

  .log-container {
     position: relative;
     width: 100%;
     height: 100%;
     margin: 0;
     padding: 0;
  }

  .login-form-forgot {
      text-align: right;
      margin-bottom: 20px;
      flex: 1;
  }

  .log-line {
     line-height: 1.6;
  }

  .log-sub-line {
     line-height: 1.8;
  }

  .log-rmb-forgot-container{
    display: flex;
  }

  .log-content-container {
     position: relative;
     margin-left: auto;
     margin-right: auto;
     display: flex;
     flex: none;
     flex-direction: column;
     align-items: center;
     background: white;
     border-radius: 8px;
     padding: 20px;
     min-width: 30%;
     margin-top: 10vh;
     margin-bottom: 10vh;
  }

  .log-body {
     position: relative;
     text-align: center;
  }

  .log-remember {
    text-align: left;
     margin-bottom: 20px;
     flex: 1;
  }

  .log-btn {
     margin-right: 10px;
     background-color: #57a6d0;
     border: 1px solid #57a6d0;
     width: 100%;
     border-radius: 4px;
     padding: 10px;
     height: 40px;
  }

  .log-btn-calculate :hover{
    margin-right: 10px;
    background-color: #57a6d0;
    border: 1px solid #57a6d0;
    width: 200px;
    border-radius: 4px;
    padding: 10px;
    height: 40px;
  }

  .log-custom-footer.ant-layout-footer {
     padding: 0;
     width: 100%;
     bottom: 0;
     position: static;
  }

  .log-button2 {
     margin-right: 25px;
     background-color: Transparent;
     margin-bottom: 20px;
     border: 1px solid #57a6d0;
     width: 100%;
     border-radius: 4px;
     padding: 10px;
     height: 40px;
  }

  .signup-button {
    margin-right: 25px;
    background-color: #fff;
    margin-bottom: 20px;
    border: 1px solid #57a6d0;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    height: 40px;
  }

  @media screen and (min-width: 320px) {
     .log-container { height: 100vh; }
  }

  @media screen and (min-height: 500px) {

     .log-body {
        margin-left: 5%;
        margin-right: 5%;
     }

     .log-custom-footer.ant-layout-footer {

     }
  }

  @media screen and (max-width: 768px) {
     .log-content-container {
       max-width: 300px;
     }
  }

  @media screen and (max-width: 320px), screen and (max-height: 320px) {
     .log-custom-footer.ant-layout-footer {
        position: static;
     }
  }
  .or {
      width: 100%;
      height: 51px;
      text-align: center;
      font-family: BentonSans, 'Rubik-1', 'Lato', Arial, sans-serif;
      font-weight: 400;
      font-size: 22px;
      letter-spacing: normal;
      line-height: 31px;
      margin-top: 50px;
      border-top: 1px solid #616161;
  }

  .lines {
    width: 100%;
    height: 51px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: Helvetica, 'Didact Gothic', 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
  }
  .lines:before {
    margin-left: -100%;
  }
  .lines:after {
    margin-right: -100%;
  }
  .lines:before, .lines:after {
    display: inline-block;
    content: '';
    background-color: #bfbfbf;
    height: 1px;
    width: 100%;
    vertical-align: middle;
    box-sizing: border-box;
    border: solid #FFF;
    border-width: 0 10px;
  }
