@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css?family=Merriweather');

@import url('https://fonts.googleapis.com/css?family=Didact Gothic&display');
@import url('https://fonts.googleapis.com/css?family=Rubik:400,900&display');
@import url('https://fonts.googleapis.com/css?family=Raleway&display');

@font-face {
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.header-container.ant-layout-header  {
	position: relative;
	background: white;
	width: 100%;
	height: auto;
	line-height: auto;
	display: flex;
	flex-direction: column;
}

.header-up-container1 {
	position: relative;
	background: transparent;
	width: 100%;
	height: 30px;
}

.container2 {
	width: 80%;
	margin-left: 10%;
}


.header {
  position: relative;
	z-index: 999; }

	.header__top {
		height: 43px;
		padding: 8px;
		vertical-align: middle;
	}

.header__top__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.header__top__button .cr-btn::before {
  display: none; }

.header__top__button .cr-btn:hover {
  border-color: #00AC1F; }

.header__top__info {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .header__top__info li {
    display: inline-block;
    padding: 0 30px;
    vertical-align: middle;
    line-height: 1; }
    .header__top__info li:not(:last-child) {
      border-right: 1px solid rgba(255, 255, 255, 0.6); }
    .header__top__info li:first-child {
      padding-left: 0; }
    .header__top__info li:last-child {
      padding-right: 0; }
    .header__top__info li a {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
			color: #a4aeb0;
			vertical-align: middle; }

			.header__top__info li span {
				color: #fff;
				font-size: 16px;
				font-weight: 300;
				line-height: 1;
				vertical-align: middle;
			}
      .header__top__info li a i {
        padding-right: 7px; }
      .header__top__info li a:hover {
        color: #00AC1F; }

/* Header Bottom Styles */
.header__bottom__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.header__logo a {
  display: inline-block;
	padding: 30px 0;
}

/* Sticky Header */
.sticky--header.is-sticky .header__bottom {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-animation: slideInDown 0.5s ease-in-out 1 both;
  -moz-animation: slideInDown 0.5s ease-in-out 1 both;
  -ms-animation: slideInDown 0.5s ease-in-out 1 both;
  -o-animation: slideInDown 0.5s ease-in-out 1 both;
  animation: slideInDown 0.5s ease-in-out 1 both;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }

.sticky--header.is-sticky .header__logo a {
  padding: 15px 0; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__top__info li {
    padding: 0 18px; }
  .sticky--header {
    position: relative; } }

@media only screen and (max-width: 767px) {
  .header__top__inner {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: space-between;
	}
	.header__top__button {
		margin-bottom: 12px;
	}
  .header__top__info {
    margin-bottom: 15px;
    text-align: center; }
    .header__top__info li {
      padding: 0 20px; }
      .header__top__info li:first-child {
        padding-left: 0px; }
      .header__top__info li:last-child {
        padding-right: 20px; }
  .sticky--header {
    position: relative; } }

@media only screen and (max-width: 575px) {
  .mean-container .mean-nav {
    width: 100%; } }


  @media only screen and (max-width: 575px) {
	.mean-container .mean-nav {
		width: 100%; } }

	  .main-navigation {
				display: -webkit-box !important;
				display: -webkit-flex !important;
				display: -moz-flex !important;
				display: -ms-flexbox !important;
				display: flex !important;
				-webkit-align-self: stretch;
				-moz-align-self: stretch;
				-ms-flex-item-align: stretch;
				align-self: stretch;
				-webkit-box-align: center;
				-ms-flex-align: center;
				-webkit-align-items: center;
				-moz-align-items: center;
				align-items: center;
}

		.main-navigation ul {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -moz-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-align-self: stretch;
		  -moz-align-self: stretch;
		  -ms-flex-item-align: stretch;
		  align-self: stretch;
		  -webkit-box-align: center;
		  -ms-flex-align: center;
		  -webkit-align-items: center;
		  -moz-align-items: center;
		  align-items: center;
		  padding-left: 0;
		  margin-bottom: 0; }
 .main-navigation ul li {
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-align-self: stretch;
			-moz-align-self: stretch;
			-ms-flex-item-align: stretch;
			align-self: stretch;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			-moz-align-items: center;
			align-items: center;
			padding: 0 20px;
	}

	.main-navigation ul li a {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-align-self: stretch;
		-moz-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-moz-align-items: center;
		align-items: center;
		color: #647072;
		font-weight: 600;
		font-size: 16px;

	}

	  @media only screen and (min-width: 992px) and (max-width: 1199px) {
		.main-navigation ul li {
			padding: 0 17px; }
	}

		.navLink {
			color:rgba(0, 0, 0, 0.85);
		}

		.navLink :hover {
			color: #fff;
		}

		.navLink2 {
			color: #fff;
		}
		.main-navigation ul li button:after,
		.main-navigation ul li a:after {
			content: '';
			display: block;
			height: 1px;
			width: 0px;
			background: transparent;
			transition: width 0.5s ease, background-color 0.5s ease;
		}
		.main-navigation ul li:hover button,
		.main-navigation ul li:hover a {
			text-decoration: none;
		}
		.main-navigation ul li:hover button:after,
		.main-navigation ul li:hover a:after {
			width: 100%;
			background: blue;
		}
/**
Button Styles
*/

a.cr-btn, button.cr-btn, .cr-btn {
	display: inline-block;
	background: rgba(59,172,240);
	padding: 11px 30px;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	vertical-align: middle;
	overflow: hidden;
	border: 1px solid #20407a;
	-webkit-transition: border-color 0.5s ease-in-out 0s;
	-moz-transition: border-color 0.5s ease-in-out 0s;
	-ms-transition: border-color 0.5s ease-in-out 0s;
	-o-transition: border-color 0.5s ease-in-out 0s;
	transition: border-color 0.5s ease-in-out 0s; }
	a.cr-btn span, button.cr-btn span, .cr-btn span {
	  position: relative;
	  line-height: auto; }
	a.cr-btn:before, button.cr-btn:before, .cr-btn:before {
	  content: "";
	  left: 50%;
	  top: 50%;
	  height: 400px;
	  width: 400px;
	  position: absolute;
	  -webkit-transform: translate(-50%, -50%);
	  -moz-transform: translate(-50%, -50%);
	  -ms-transform: translate(-50%, -50%);
	  -o-transform: translate(-50%, -50%);
	  transform: translate(-50%, -50%);
	  -webkit-transition: box-shadow 0.5s ease-in-out 0s;
	  -moz-transition: box-shadow 0.5s ease-in-out 0s;
	  -ms-transition: box-shadow 0.5s ease-in-out 0s;
	  -o-transition: box-shadow 0.5s ease-in-out 0s;
	  -webkit-transition: -webkit-box-shadow 0.5s ease-in-out 0s;
	  transition: -webkit-box-shadow 0.5s ease-in-out 0s;
	  transition: box-shadow 0.5s ease-in-out 0s;
	  transition: box-shadow 0.5s ease-in-out 0s, -webkit-box-shadow 0.5s ease-in-out 0s;
	  border-radius: 100%; }
	a.cr-btn:hover, button.cr-btn:hover, .cr-btn:hover {
	  color: #fff;
	  border-color: #333982; }
	  a.cr-btn:hover:before, button.cr-btn:hover:before, .cr-btn:hover:before {
		-webkit-box-shadow: inset 0 0 0 400px #333982;
		-moz-box-shadow: inset 0 0 0 400px #333982;
		-ms-box-shadow: inset 0 0 0 400px #333982;
		-o-box-shadow: inset 0 0 0 400px #333982;
		box-shadow: inset 0 0 0 400px #333982; }
	a.cr-btn.cr-btn--lg, button.cr-btn.cr-btn--lg, .cr-btn.cr-btn--lg {
	  padding: 12px 30px; }
	a.cr-btn.cr-btn--sm, button.cr-btn.cr-btn--sm, .cr-btn.cr-btn--sm {
	  padding: 8px 40px; }
	a.cr-btn.cr-btn--transparent, button.cr-btn.cr-btn--transparent, .cr-btn.cr-btn--transparent {
	  font-weight: 600;
	  font-size: 16px;
	  color: #647072;
	  background: transparent;
	  border-color: #9f9f9f; }
	  a.cr-btn.cr-btn--transparent:hover, button.cr-btn.cr-btn--transparent:hover, .cr-btn.cr-btn--transparent:hover {
		border-color: #00AC1F;
		color: #fff; }
		a.cr-btn.cr-btn--transparent:hover:before, button.cr-btn.cr-btn--transparent:hover:before, .cr-btn.cr-btn--transparent:hover:before {
		  -webkit-box-shadow: inset 0 0 0 400px #00AC1F;
		  -moz-box-shadow: inset 0 0 0 400px #00AC1F;
		  -ms-box-shadow: inset 0 0 0 400px #00AC1F;
		  -o-box-shadow: inset 0 0 0 400px #00AC1F;
		  box-shadow: inset 0 0 0 400px #00AC1F; }

  /* Readmore Buttons */
  .cr-readmore, a.cr-readmore, button.cr-readmore {
	display: inline-block;
	border: 1px solid #00AC1F;
	padding: 3px 18px;
	color: #00AC1F; }

  .cr-readmore-2, a.cr-readmore-2, button.cr-readmore-2 {
	display: inline-block;
	color: #00AC1F;
	font-weight: 500; }
	.cr-readmore-2::after, a.cr-readmore-2::after, button.cr-readmore-2::after {
	  content: "\f101";
	  font-family: 'fontAwesome';
	  padding-left: 5px;
	  -webkit-transition: all 0.3s ease-in-out 0s;
	  -o-transition: all 0.3s ease-in-out 0s;
	  transition: all 0.3s ease-in-out 0s; }
	.cr-readmore-2:hover, a.cr-readmore-2:hover, button.cr-readmore-2:hover {
	  color: #00AC1F; }
	  .cr-readmore-2:hover::after, a.cr-readmore-2:hover::after, button.cr-readmore-2:hover::after {
		padding-left: 10px; }

  a.video-btn, button.video-btn, .video-btn {
	display: inline-block;
	height: 60px;
	width: 60px;
	text-align: center;
	border-radius: 100px;
	background: #00AC1F;
	color: #ffffff;
	line-height: 1;
	font-size: 28px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s; }
	a.video-btn i, button.video-btn i, .video-btn i {
	  line-height: 60px;
	  padding-left: 5px; }
	a.video-btn:hover, button.video-btn:hover, .video-btn:hover {
	  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

.header-low-container1 {
	position: relative;
	background: transparent;
	width: 100%;
	display: flex;
	flex-direction:row;
	align-items:center;
	justify-content: space-between;
	padding: 20px;
}

.bg--theme {
	background: #00AC1F; }

  .bg--secondary {
	background: #333982; }

  .bg--blue {
	background: #0b5ca9; }

  .bg--green {
	background: #154989; }

  .bg--yellow {
	background: #EFC418; }

  .bg--orange {
	background: #EF785A; }

  .bg--light {
	background: #999999; }

  .bg--dark {
	background: #222222; }

  .bg--dark--light {
	background: #2a2a2a; }

  .bg--black {
	background: #000000; }

  .bg--white {
	background: #ffffff; }

  .bg--grey {
	background: #fafafa; }

  .bg--grey--light {
	background: #f2f2f2; }

.header-welcome {
	position: absolute;
	right: 13vw;
	color: white;
	top: 5px;
}

.header-welcome-text {
	font-size: 8px
}

.header-logo1 {
	margin-left: 10px;
	max-height: 60px;
	margin: 0;
	padding: 0;
}

.header-left-parent1 {
	position: relative;
	width: 20%;
	margin-left: 10vw;
	padding: 0;
}

.header-social-media {
	position: relative;
	display: flex;
	flex-direction:row;
	justify-content:center;
}

.header-right-parent1 {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-right: 9.5vw;
}

.header-right {
	position: relative;
	right: 0;
	display: flex;
	flex-direction: row;
}

.header-button {
	background-color: Transparent;
  color: black;
  font-size: .9em;
  margin-bottom: 0px;
	max-width: 70px;
}

.header-button-selected {
	background-color: Transparent;
  color: white;
  font-size: .9em;
  margin-bottom: 0px;
}

.header-button-parent-selected {
	background: #E82732;
	height: 100%;
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	justify-content: center;
	align-items: center;
}

.header-sign-up1 {
	color: rgba(255, 255, 255, .7);
	margin-right: 25px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: .9em;
}

.header-login1 {
	color: rgba(255, 255, 255, .7);
	margin-right: 25px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: .9em;
}

.header-social-logo {
	height: 100%;
	display: flex;
	flex-direction:row;
	text-align: center;
}

.header-social-logo-icon {
	height: 30px;
	margin-left: 8px;
}


.header-mobile-top-up {
	margin-right: 20px;
}

.drawer {
	display: none
}

.drawer-button.ant-btn {
	display: none !important;
}

.drawer-button-logged-in.ant-btn {
	display: none !important;
}

.drawer-close.ant-btn {
  border: 1px solid transparent;
  height: 50px;
  width: 50px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: white;
}

@media screen and (max-width: 1024px) {
   .drawer-button.ant-btn  {
     top:30px;
     left: 0;
     position: absolute;
     display: inline !important;
     border-top-right-radius: 4px;
     border-bottom-right-radius: 4px;
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;
     border-left-color: transparent;
   }

   .drawer-button-logged-in.ant-btn  {
     top: 75px;
     left: 0;
     position: absolute;
     display: inline !important;
     border-top-right-radius: 4px;
     border-bottom-right-radius: 4px;
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;
     border-left-color: transparent;
   }

   .drawer { display: initial; }
   .header-right-parent { display: none; }
   .header-button { color: #666666; }
	 .header-menu-container { display: none; }
	 .navigation-inner-right { display: none;}
	 .navigation-inner-middle { display: none;}
	 .navigation-inner2 { display: flex; align-items: center; align-content: center}
	 .navigation-inner-left { display: inline-block; }
   .header-left-parent {margin-left: 10vw;}
   .header-low-container1 { flex-direction: column; padding-left: 0; padding-right: 0;}
   .header-left-parent1 { width: 80%; margin-left: 0; display: flex; justify-content: center;}
   .header-right-parent1 { flex-direction: column; width: 100%; margin-top: 20px; margin-right: 0; padding-left: 15vw; padding-right: 15vw;}
   .header-welcome { position: relative; width: 100%; text-align: center; right: 0; left: 0;}
   .header-social-media { margin-top: 8px;}
	 .header-up-container1 { height: 60px; display: flex; align-items: center;	justify-content: center;}
	 .header-logo1 {
	 		max-height: 40px;
	 }
}

@media screen and (max-width: 900px) {
   .header-left-parent {margin-left: 10vw;}
   .header-right-parent {margin-right:20px;}
   .header-button { margin-right: 10px;}
   .container2 {
     margin-left: 15%;
   }
}

@media screen and (max-height: 700px) {
  .navigation ul .list-item button,
  .navigation ul .list-item a {
    font-size: 11.5px !important;
    margin: 4px !important;
  }
  .navigation ul .list-button-signup {
    font-size: 11.5px !important;
  }
  .navigation ul .list-button-signup a {
    font-size: 11.5px !important;
    margin: 4px !important;
    width: 80px !important;
    height: 35px !important;
  }
}

.navigation {
  background: white; /*linear-gradient(135deg, #003d6d 79%, #00568b 40%, #00568b 100%);*/
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 6px 6px 0 rgba(0, 0, 0, 0.2);
  margin-top: 0px;
	height: 100px;
	vertical-align: middle;
}

.navigation-inner {
	display: flex;
	width: 100%;
}

.navigation-inner2 {
	display: none;
	width: 100%;
}

.navigation-inner-left {

}

.navigation-inner-middle {
	flex: 3;
	padding: 15px 0px;
}

.navigation-inner-middle2 {


}

.navigation-inner-right {
	padding: 10px 0px;
	text-align: right;
	align-items: right;
	align-content: right;
}

.navigation-inner-mobile {
	width: 100%;
	padding: 5px;
	align-content: center;
	flex: 1;
}
.navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.navigation ul .list-item {
  display: inline-block;
  font-size: 10px;
  vertical-align: middle;
}
.navigation ul .list-item:first-child {
  margin-top: 0;
}
.navigation ul .list-item button,

.navigation ul .list-item a {
  display: inline-block;
  font-size: 15px;
  color: #647072;
  padding: 0;
  margin-left: 28px;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-right: 0px;
	padding-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
	font-family: 'Rubik', Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}
.navigation ul .list-item button:after,
.navigation ul .list-item a:after {
  content: '';
  display: block;
  margin: 5px auto auto auto;
  height: 3px;
  width: 0px;
  background: #154989;;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.navigation ul .list-item.current-section button:after,
.navigation ul .list-item.current-section a:after {
  content: '';
  display: block;
  margin: 5px auto auto auto;
  height: 3px;
  width: 100%;
  background: #154989;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.navigation ul .list-item:hover button,
.navigation ul .list-item:hover a {
  text-decoration: none;
}
.navigation ul .list-item:hover button:after,
.navigation ul .list-item:hover a:after {
  width: 100%;
  background: #154989;
}

.navigation ul .list-button-login {
  display: inline-block;
  font-size: 16px;
	color: #647072;
  vertical-align: middle;
}

.navigation ul .list-button-login:first-child {
  margin-top: 0;
}
.navigation ul .list-button-login button,

.navigation ul .list-button-login a {
	background-color: #154989;
	border: 1px solid #154989;
  display: inline-block;
  font-size: 16px;
  color: #fff;
	height: 40px;
	border-radius: 4px;
	text-align: center;
	text-decoration: none;
	width: 120px;

  padding: 0;
  margin: 15px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
}

.navigation ul .list-button-login:hover button,
.navigation ul .list-button-login:hover a {
	text-decoration: none;
	background-color: #fff;
	color: #3e3e3e;
}
.navigation ul .list-button-login:hover button:after,
.navigation ul .list-button-login:hover a:after {
  width: 100%;
  background: #fff;
}

.navigation ul .list-button-signup {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
}

.navigation ul .list-button-signup:first-child {
  margin-top: 0;
}
.navigation ul .list-button-signup button,

.navigation ul .list-button-signup a {
	background-color: transparent;
	border: 1.5px solid #154989;
  display: inline-block;
  font-size: 16px;
  color: #647072;
	height: 40px;
	border-radius: 20px;
	text-align: center;
	text-decoration: none;
	width: 120px;
  padding: 0;
  margin: 15px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
}

.navigation ul .list-button-signup:hover button,
.navigation ul .list-button-signup:hover a {
	text-decoration: none;
	background-color: #154989;
	color: #fff;
}
.navigation ul .list-button-signup:hover button:after,
.navigation ul .list-button-signup:hover a:after {
  width: 100%;
  background: #154989;
}

.mobile-number .ant-input-group .ant-input {
  height: 46px;
  border-radius: 0px;
}
