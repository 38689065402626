.tn-print-box {
	position: relative;
	display: flex;
	margin-top: 2vh;
	width: 100%;
	margin-bottom: 10px;
	justify-content: space-around;

}

.tn-print-box-right {
	flex: 2;
}

.tn-print-box-middle {
	flex: 1;
}

.tn-print-box-left {
	flex: 2;
}

.tn-pagination-box-item {
	margin-left: 0px;
	margin-right: 5px;
	height: 30px;
	min-width: 100px;
	font-size: 16px;
	color: #0b5ca9;
	border: 1px solid #0b5ca9;
	border-radius: 0px;

}

.print-btn.ant-btn {
	margin-bottom: 10%;
}

.back-btn.ant-btn {
	display: none;
}


@media screen and (max-width: 1024px) {
	.print-btn.ant-btn {
		display: none;
	}
	.back-btn.ant-btn {
		margin-bottom: 10%;
		display: block;
	}
}
