.footer-container {
	background: #46c2cf;
	width: 100%;
	bottom: 0;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.footer-get-the-app {
	margin-top: 30px;
	margin-bottom: 0;
	color: white;
}

.footer-send-money {
	margin-top: 20px;
	margin-left: 3%;
	margin-right: 3%;
	color: white;
}

.footer-apps {
	margin-top: 30px;
}

.footer-apps-logo {
	margin: 8px;
	width: 10%;
	min-width: 150px;
}

.footer-social-logo {
	display: flex;
	flex-direction:row;
	text-align: center;
	justify-content: center;
}

.footer-social-logo-icon {
	height: 30px;
	margin-left: 8px;
}

.footer-parent-info-area {
	position: relative;
	display: flex;
	justify-content:center;
}

.footer-sub-parent-info-area {
	position: relative;
	display: flex;
	flex-direction:row;
	width: 80%;
	justify-content:space-around;
	margin-top: 30px;
}

.footer-info-area {
	position: relative;
	display: flex;
	flex-direction:column;
	color: white;
}

.footer-info-title {
	color: white;
}

.footer-info-text-divider {
	margin-bottom: 8px;
	color: #154989;
	background-color: #154989;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
	font-size: 18px;
}

.footer-info-text {
	margin-bottom: 8px;
	color: #154989;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
	font-size: 18px;
}

.footer-divider.ant-divider.ant-divider-horizontal {
	background: #154989;
}

.footer-address-area {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	margin-bottom: 20px;
}

.footer-address-text {
	color: #154989;
	font-size: 14px;
	margin: 0 300px;
}

.footer-parent-copyright-area {
	position: relative;
	display: flex;
	justify-content:center;
}

.footer-copyright-area {
	position: relative;
	display: flex;
	flex-direction:row;
	flex-wrap: wrap;
	width: 50%;
	min-width: 300px;
	justify-content:space-around;
	color: #154989;
	align-items:center;
}

.footer-copyright-text {
	margin-right: 8px;
	color: #154989;
}

@media screen and (max-width: 1024px) {
	.footer-address-text {
		font-size: 14px;
		margin: 0 200px;
	}
}

@media screen and (max-width: 768px) {
	.footer-address-text {
		font-size: 14px;
		margin: 0 100px;
	}
}

@media screen and (max-width: 500px) {
	.footer-address-text {
		font-size: 12px;
		margin: 0 20;
	}

	.footer-info-text {
		font-size: 16px;
	}
}
