.payment-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border-radius: 4px;
  padding-top: 20px;
  width: 50%;
  margin-top: 20vh;
  margin-bottom: 10vh;
  min-width: 400px;
}

.payment-content {
  display : flex;
  justify-content:center;
  margin-top: 18%;
  margin-bottom:5%;
}

.print-form-content-container {
  display: flex;
  justify-content: center;
  align-items : center;
  flex-direction: column;
  padding: 5%;
}

.payment-body  {
  position: relative;
  text-align: center;
  margin-top: 10px;
  width: 50%;
}

.payment-dropdown > .ant-form-item-children {
   position: relative;
}

.payment-description {
  display: flex;
  flex-direction:column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-important-text {
  color: red;
}

.payment-promo-parent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-promo-child {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.payment-promo-text {
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {

   .payment-content {
     margin-top: 40%;
   }
}

@media screen and (max-width: 800px) {
   .saved-p-body {
     padding-left: 0px;
     padding-right: 0px;
     height: 10%;
   }

   .payment-body {
     width: 80%;
   }
}

@media print {
  @page {
    /* Remove header and footer printing lines. */
    margin: 1mm 15mm 0 15mm;
  }
  html, body {
    /* Remove all the pages but first */
    height:100%;
    overflow: hidden;
  }
}
