.dashboard-content-container {
  position: relative;
  margin-bottom: 10vh;
}

.stories-body {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.terms-body {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: auto;
  padding-left: 15%;
  padding-right: 15%;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  line-height: 1.6;
  color: #647072;
}

.get-help-body {
  position: relative;
  margin-top: 15vh;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 10%;
  padding-right: 10%;
}

.faq-content-container {
  position: relative;
  display: inline;
  width: 100%;
  padding-left: 13vw;
  padding-right: 13vw;
  margin-bottom: 10vh;
}

.faq-body {
  position: relative;
  margin-top: 50px;
}

.cookie-policy-body {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  margin-bottom: 50px;
  min-height: 100vh;
  padding-left: 15%;
  padding-right: 15%;
}

.dashboard-body {
  margin-top: 8%;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.media-body {
  margin-top: 5%;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.cash-points-body {
  margin-top: 8%;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.agent-body {
  margin-top: 8% !important;
  margin-bottom: 8% !important;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.about-body {
}

.terms-ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.terms-ol > .terms-li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.terms-ol > .terms-li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.terms-li terms-ol > .terms-li {
  margin: 0;
}

.terms-li terms-ol > .terms-li:before {
  content: counters(item, ".") " ";
}

.terms-list-body {
  position: relative;
  display: flex;
  flex-direction: column;
}

.partners-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.partners-logo {
  width: 18vw;
  object-fit: contain;
}

.about-mzansi {
  width: 50%;
  height: 80vh;
  margin-right: 5vh;
  background-repeat: no-repeat;
  background-position: 50%35%;
  background-image: url(../images/ecocacashza-1.jpg) !important;
}

.about-mzansi-text {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.about-us-text {
  margin-top: 20px;
}

.misc-top {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.misc-top-title {
  padding: 6px;
  border: 4px solid #f4f4f433;
  font-size: 48px;
  text-align: center;
  color: white;
  font-family: BentonSans, 'Rubik', 'Oswald', sans-serif;
}

.misc-top-faqs {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/115135-Launch-Phase-SA-digitalv-35.jpg);
  background-position: 50% -98px;
  background-size: cover;
  background-repeat: no-repeat;
}

.misc-top-cash-out {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/blue-bg.jpg);
  background-position: 50% -10px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-top-find-agent {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/EcoCash-Agent.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-top-title-white {
  padding: 6px;
  border: 4px solid #0c5ca9;
  font-size: 30px;
  text-align: center;
  color: white;
}

.misc-top-send-money {
  background-image: url(../images/send-money.jpg);
  background-position: 50% -10px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-top-gen {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/banner-zesa-e1537346816582.jpg);
  background-position: 50% -98px;
  background-size: cover;
  background-repeat: no-repeat;
}

.send-money-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.send-money-body > div {
  padding-left: 12vw;
  padding-right: 13vw;
}

.send-money-row-1 {
  background-color: white;
  width: 100%;
}

.send-money-row-1-child {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
}

.send-money-row-3-child {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.send-money-row-3-child-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  padding-top: 20px;
  padding-bottom: 20px;
  background: white;
}

.send-money-row-3-child-body-desc {
  width: 15vw;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.send-money-row-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f8fa;
  width: 100%;
}

.send-money-row-1-child-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-right: 3vh;
  padding-left: 3vh;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
}

.send-money-row-1-child-body:first-child {
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(2) {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(3) {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(4) {
  border-left: 0;
  border-bottom: 0;
}
.send-money-row-1-child-body:nth-child(5) {
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(6) {
  border-right: 0;
  border-bottom: 0;
}

.send-money-row-1-child-title {
  font-size: 18px;
  color: black;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.send-money-row-1-child-body-desc {
  width: 20vw;
  text-align: center;
  color: #647072;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 400;
}

.send-money-body-title {
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 1.5;
  font-size: 48px;
  text-align: center;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: #242d2e;
}

.send-money-row-2-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.send-money-app {
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.send-money-app-title {
  font-size: 20px;
  color: black;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.send-money-app-desc {
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-size: 16px;
}

.get-quote-img {
  height: 50vh;
}

/* breadwinner home page */

.bwl-main-banner {
  width: 100%;
  height: 100vh;
  background: #000 url("../images/banner-image.jpg") no-repeat center;
  background-size: cover;
}

.rh-main-banner {
  width: 100%;
  height: 100vh;
  background: #000 url("../images/remit-hope-banner.jpg") no-repeat center;
  background-size: cover;
}

.bwl-aside-gradient {
  color: white;
  width: 50%; 
  height: 100vh;
  background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
}

.rh-aside-gradient {
  color: white;
  width: 50%; 
  height: 100vh;
  background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
}

.bwl-main-banner-container {
  margin: 0 auto;
}

.bwl-main-banner-sub-container {
  width: 80%;
  margin: 0 auto;
  padding: 15% 20% 0 0;
}

.bwl-breadwinner-policy-logo {
  margin: 0 auto;
  height: 220px;
  width: auto;
}

.rh-remit-hope-logo {
  margin: 0 auto;
  height: 220px;
  width: auto;
  object-fit: cover;
}

.bwl-main-banner-text {
  color: white;
  font-size: 28px;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rh-main-banner-text {
  color: white;
  font-size: 28px;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rh-sub-banner-text {
  color: white;
  font-size: 20px;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.bwl-how-it-works-section {
  background: #fff url("../images/bwl-how-it-works-curve.svg") no-repeat center;
  background-size: cover;
  color: white;
  box-sizing: border-box;
  height: auto;
  padding-bottom: 100px;
}

.bwl-how-it-works-heading {
  font-size: 70px;
  padding: 30px 0;
  color: white;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  text-align: center;
}

.remit-hope-category-heading {
  font-size: 50px;
  padding: 0 0;
  margin: 0 0;
  margin-top: 32px;
  color: #104075;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  text-align: center;
}

.bwl-how-it-works-text {
  font-size: 30px;
  line-height: 35px;
  padding: 0 80px;
  margin: 0 auto;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  text-align: center;
}

.bwl-how-it-works-text-second-p {
  padding: 60px 80px;
  margin: 0 0 150px 0;
  text-align: left;
}

.bwl-htgs-curved-bg {
  background-image: url("../images/bwl-curved-bg.svg");
  background-position: center;
  background-size: cover;
  margin: 0 0 30px 0;
}

.bwl-htgs-heading {
  font-size: 70px;
  padding: 30px 0;
  color: #104075;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.bwl-htgs-first-level-bubbles {
  height: 150px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 50px rgba(7, 42, 68, .23);
  margin: 80px 0;
}

.bwl-htgs-bubble-padding {
  padding: 20px;
}

.bwl-htgs-bubble-icon-size {
  width: 40px;
  height: 40px;
}

.bwl-htgs-bubble-heading-margin {
  margin: 10px 0;
}

.bwl-htgs-bubble-text {
  font-size: 14px;
}

.bwl-hgts-second-level-bubbles {
  height: 150px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 50px rgba(7, 42, 68, .23);
  margin: 100px 0;
}

.bwl-htgs-center-mobile-phone {
  width: 252px;
  height: 600px;
  background-image: url("../images/bwl-mobile.png");
  background-size: cover;
  background-position: 50% 50%;
}

.bwl-play-app-store-badge-row {
  margin: 0 0 150px 0;
}

.bwl-play-app-store-badge-size {
  height: 65px;
  width: 260px;
}

.pointer {
  cursor: pointer;
}

.bwl-benefits-heading {
  border: #104075 solid 2px;
  border-radius: 50px;
  font-size: 60px;
  padding: 0px 50px;
  display: inline;
  margin: 20px 0;
  color: #104075;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  text-align: center;
}

.bwl-benefits-text {
  color: #104075;
  font-size: 30px;
  line-height: 35px;
  padding: 0 60px 50px;
  margin: 0 auto;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  text-align: center;
}

.bwl-benefits-list {
  color: #104075;
  font-size: 30px;
  line-height: 35px;
  padding: 0 60px 25px;
  margin: 0 auto;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  text-align: left;
}

.bwl-address {
  list-style-type: none;
}

.bwl-justify-self-center {
  display: flex;
  justify-self: center;
}

.rh-justify-self-center {
  width: 100%;
  display: flex;
  justify-self: center;
  justify-content: center;
}

.bwl-justify-content-center {
  display: flex;
  justify-content: center;
}

.bwl-read-more-btn, .bwl-read-more-btn:hover {
  padding: 10px 30px;
  border-radius: 20px;
  background-color: #104075;
  margin: 20px 0;
  color: white;
  font-weight: regular;
  font-size: 1.3em;
  text-decoration: none;
}

.rh-learn-more-btn, 
.rh-learn-more-btn:hover, 
.rh-learn-more-btn:focus, 
.rh-learn-more-btn:focus-visible {
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
  background-color: #104075;
  margin: 20px 0;
  color: white;
  font-weight: regular;
  font-size: 1.3em;
  text-decoration: none;
  outline: none;
}

.bwl-benefits-grey-bg-padding {
  background-color: #fafafa;
  padding: 20px 0;
}

.bwl-benefits-padding {
  padding: 20px 0;
}

.bwl-remove-margin {
  margin: 0 !important;
}

.bwl-disclaimer {
  font-size: 10px;
  line-height: 15px;
  text-align: center;
}
/* end of breadwinner landing page styling */

@media screen and (max-width: 768px) {
  .about-mzansi {
    display: none;
  }
  .partners-logo {
    width: 20vw;
    object-fit: contain;
  }
  .dashboard-body {
    margin-top: 10%;
  }
  .media-body {
    margin-top: 10%;
  }

  .bwl-main-banner-sub-container {
    padding: 0 10px;
  }

  .bwl-how-it-works-text {
    font-size: 35px;
    line-height: 40px;
    padding: 0 20px;
  }

  .bwl-benefits-text, .bwl-benefits-list {
    font-size: 35px;
    line-height: 40px;
    padding: 0 20px;
  }

  .bwl-benefits-list {
    font-size: 35px;
    line-height: 40px;
    padding: 0 20px;
  }

  .bwl-main-banner-text {
    font-size: 20px;
  }

  .bwl-address {
    list-style-type: none;
    padding-left: 20px;
  }

}

.about-top-bg {
  background-image: url(../images/about-us.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.hiw-top-bg {
  background-image: url(../images/how-it-works.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.terms-top-bg {
  background-image: url(../images/terms-conditions.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.faqs-top-bg {
  background-image: url(../images/115135-Launch-Phase-SA-digitalv-35.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.help-top-bg {
  background-image: url(../images/contact-us.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.agent-top-bg {
  background-image: url(../images/EcoCash-Agent.jpg);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-page-overlay {
  background-color: #46c2cf8c;
  padding-top: 145px;
  padding-bottom: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hiw-app-text {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 8px;
}

.hiw-app-text-title {
  font-size: 18px;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: #242d2e;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.hiw-app-text-desc {
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  color: #647072;
  font-weight: 400;
  line-height: 1.75;
}

.hiw-app-area-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 40%;
  justify-content: center;
}

.hiw-app-area-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.hiw-app-reg-desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 860px) {
  .send-money-row-1-child-body-desc {
    width: 100%;
  }

  .send-money-row-1-child > div {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
  }

  .send-money-app {
    width: 100%;
    margin-bottom: 60px;
  }

  .send-money-row-3-child-body-desc {
    width: 100%;
  }

  .send-money-row-1-child-body {
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 3vh;
    padding-left: 3vh;
  }

  .hiw-app-area-column {
    width: 80%;
    align-items: center;
  }

  .hiw-app-area-desc {
    justify-content: center;
  }

  .cash-points-body {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 1050px) {
  .misc-top-faqs {
    background-size: auto;
    background-position: 0;
  }
  .misc-top-gen {
    background-size: auto;
    background-position: 0;
  }
  .about-mzansi-text {
    width: 80%;
  }
}

.cashout-content-container {
  position: relative;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.cashout-table-body {
  position: relative;
  width: 100%;
  min-height: 0;
  height: auto;
  margin-top: 30px;
  padding-left: 0px;
  padding-right: 0px;
}

.panel-child {
  color: #777;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5em;
  padding: 16px;
}

.terms-item-parent {
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 900;
  /* font-family: "Lato Black", Lato, sans-serif; */
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  color: black;
}

.terms-item-child {
  margin-top: 8px;
  margin-bottom: 8px;
}

.terms-item-child-bold {
  font-weight: 900;
  /* font-family: "Lato Black", Lato, sans-serif; */
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  color: black;
  margin-top: 8px;
  margin-bottom: 8px;
}

.terms-item-bold {
  font-weight: 900;
  /* font-family: "Lato Black", Lato, sans-serif; */
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: black;
}

.stories-title {
  margin-bottom: 45px;
  text-align: center;
  color: black;
  font-weight: 700;
}

.stories-image {
  object-fit: contain;
  margin-bottom: 45px;
  min-width: 10vw;
}

@media screen and (max-width: 993px) {
  .bwl-htgs-center-mobile-phone{
    display: none !important;
  }

  .bwl-htgs-bubble-container{
    flex: 0 0 50% !important;
    max-width: 500px;
  }

  .bwl-main-banner-text {
    font-size: 30px;
  }

  .bwl-how-it-works-heading {
    font-size: 60px;
    padding: 15px 0;
  }

  .bwl-benefits-heading {
    font-size: 50px;
  }

  .bwl-how-it-works-section {
    padding-bottom: 180px;
  }

  .bwl-how-it-works-text, .bwl-benefits-list {
    margin: 25px auto;
    text-align: left;
    font-size: 25px;
    line-height: 30px;
  }

  .bwl-benefits-text {
    margin: 25px auto;
    text-align: center;
    font-size: 25px;
    line-height: 30px;
  }

  .bwl-how-it-works-text-second-p {
    padding: 20px 80px;
  }

  .bwl-htgs-heading {
    font-size: 60px;
  }

  .bwl-htgs-first-level-bubbles {
    margin: 40px 0;
  }

  .bwl-aside-gradient {
    color: white;
    width: 70vw; 
    height: 100vh;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
  }

  .rh-aside-gradient {
    color: white;
    width: 70vw; 
    height: 100vh;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
  }
  
}

@media screen and (max-width: 860px) {
  .stories-image {
    object-fit: cover;
  }
  .agent-page-text {
    font-size: 8px;
  }

  .bwl-main-banner-text {
    font-size: 35px;
  }
}

@media screen and (max-width: 768px) {
  .agent-table-area {
    width: 100% !important;
  }
  .agent-body {
    padding-left: 10%;
    padding-right: 10%;
  }

  .cookie-policy-body {
    margin-top: 30vh;
  }

  .bwl-aside-gradient {
    color: white;
    width: 100vw; 
    height: 100vh;
    background-image:  linear-gradient(to right, rgba(0, 0, 0, .6) 80%, rgba(0, 0, 0, .6));
  }

  .rh-aside-gradient {
    color: white;
    width: 100vw; 
    height: 100vh;
    background-image:  linear-gradient(to right, rgba(0, 0, 0, .6) 80%, rgba(0, 0, 0, .6));
  }

  .bwl-benefits-heading {
    font-size: 40px;
    padding: 0;
    border: none;
  }

  .bwl-how-it-works-text, .bwl-benefits-list {
    font-size: 24px;
    line-height: 30px;
    padding: 0 25px;
  }

  .bwl-benefits-text {
    font-size: 24px;
    line-height: 30px;
    padding: 0 25px;
  }

  .bwl-htgs-heading {
    padding: 0;
    margin: 0;
  }

  .bwl-htgs-bubble-padding {
    padding: 15px;
  }

  .bwl-htgs-bubble-heading-margin {
    margin: 5px 0;
  }

  .bwl-hgts-second-level-bubbles {
    margin: 50px 0;
  }
}

@media screen and (max-width: 900px) {
  .cash-point-table .ant-table-thead > tr > th {
    font-size: 13px !important;
  }

  .cash-point-table .ant-table-tbody > tr > td {
    font-size: 13px !important;
  }

  .promotions-image {
    width: 60% !important;
    height: 50vh;
  }
}

@media screen and (max-width: 768px) {
  .cash-point-table .ant-table-thead > tr > th {
    font-size: 10px !important;
  }

  .cash-point-table .ant-table-tbody > tr > td {
    font-size: 10px !important;
  }
  .promotions-image {
    width: 70% !important;
    height: 50vh;
  }
}

@media screen and (max-width: 500px) {
  .cash-point-table .ant-table-thead > tr > th {
    font-size: 9px !important;
  }

  .cash-point-table .ant-table-tbody > tr > td {
    font-size: 9px !important;
  }
  .promotions-image {
    width: 80% !important;
    height: 50vh !important;
  }

  .dashboard-body {
    margin-top: 20%;
  }

  .media-body {
    margin-top: 20%;
  }

  .bwl-main-banner-text {
    font-size: 28px;
  }

  .bwl-benefits-heading {
    font-size: 30px;
  }

  .bwl-how-it-works-text, .bwl-benefits-list {
    font-size: 20px;
    line-height: 27px;
    padding: 0 10px;
  }

  .bwl-benefits-text {
    font-size: 20px;
    line-height: 27px;
    padding: 0 10px;
  }

  .bwl-htgs-steps-container {
    flex-direction: column;
  }

  .bwl-play-app-store-badge-size {
    height: 50px;
    width: auto;
    margin: 0 20px;
  }

  .bwl-play-app-store-badge-row {
    margin: 0 0 50px 0;
    padding: 0 0 50px 0;
  }

  .bwl-address {
    font-size: 20px;
    line-height: 30px;
  }
}

.agent-table .ant-table-thead > tr > th {
  padding: 7px 10px;
  line-height: 16px;
  background: #154989;
  color: white;
  text-align: center;
  font-size: 11px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 400;
}

.agent-table .ant-table-tbody > tr > td {
  padding: 3px 10px;
  font-size: 11px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-style: normal;
  color: #666666;
  font-weight: 400;
}

.agent-table-area {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.agent-page-text {
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .cash-out-agents {
    min-height: 400px !important;
  }

  .breadwinner-policy-container {
    width: 90% !important;
  }
}

.cash-point-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.cash-point-table .ant-table-thead > tr > th {
  padding: 7px 10px;
  line-height: 16px;
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 700;
  padding: 8px;
}

.cash-point-table .ant-table-tbody > tr > td {
  padding: 3px 10px;
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-style: normal;
  color: #666666;
  font-weight: 400;
}

.cash-out-agents {
  width: 100%;
  height: auto;
  min-height: 800px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../images/cash_out_agents.png) !important;
  margin-top: 0px;
  background-position: center;
}

ul.cross {
  margin: 0;
  list-style-type: none;
}
ul.cross > li {
  text-indent: 5px;
}

ul.cross > li:before {
  content: "x";
  font-size: 10px;
  text-indent: 5px;
  padding-right: 8px;
}

ul.tick {
  margin: 0;
  list-style-type: none;
}
ul.tick > li {
  text-indent: 5px;
}

ul.tick > li:before {
  content: "\2714\0020";
  font-size: 10px;
  text-indent: 5px;
  padding-right: 8px;
}

ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
ul.dash > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  /* margin-left: -1em; */
}

.bread-winner-h4 {
  background: #154989;
  color: white;
}

.bread-winner-h6 {
  color: #154989;
}

.breadwinner-logo {
  height: 20vh;
  min-height: 150px;
  width: auto;
  object-fit: contain;
}

.hollard-logo {
  height: 5vh;
  min-height: 50px;
  width: auto;
  object-fit: contain;
}

@media only screen and (max-width: 2000px) {
  .breadwinner-content-container {
    position: relative;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .bwl-how-it-works-section {
    /* height: 10vh; */
  }

  .bwl-how-it-works-heading {
    font-size: 50px;
  }

  .bwl-benefits-heading {
    font-size: 40px;
    padding: 0;
    border: none;
  }

  .bwl-htgs-heading {
    font-size: 50px;
  }

  .bwl-play-app-store-badge-size {
    height: 40px;
    width: auto;
    margin: 0 5px;
  }
  
  .breadwinner-content-container {
    position: relative;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 
  
  .breadwinner-policy-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }
  
  .breadwinner-policy-child-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .bwl-address {
    font-size: 15px;
    line-height: 20px;
    padding-left: 10px;
  }
}
