
.quote-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border-radius: 4px;
  padding: 40px;
  width: 70%;
  margin-top: 10%;
  margin-bottom: 10vh;
}

.quote-body--form {
  position: relative;
  text-align: center;
  margin-top: 10px;
  width: 80%;
  margin-left: 10%
}

.quote-layout {
  position: relative;
  width: 100%;
  background: linear-gradient(to right, rgb(41, 108, 187), #154989);
}

.quote-box1 {
  flex: 1;
}

.quote-box2 {
  position: relative;
  background: #f2f2f2;
  padding: 40px 240px;
  text-align: left;
  flex: 2;
}

.quote-box3 {
  flex: 1;
}

.quote-open-account {
   text-align: center;
   margin-bottom: 40px;
   font-size: 20px;
}

.quote-upper-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.quote-mid-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.quote-left-title-text {
   width: 100%;
   text-align: center;
   margin-top: 0px;
   font-size: 24px;
}

.quote-address-text {
   text-align: center;
   width: 100%;
   margin: 20px;
   font-size: 20px;
}

.quote-body {
   margin: auto;
   width: 100%;
   height: 100%;
   padding-left: 18%;
   padding-right: 18%;
   padding-top: 5%;
   padding-bottom: 5%;
}

.quote-body-2 {
   position: relative;
   width: 70%;
   display: flex;
   flex-direction: column;
   background-color: whitesmoke;
   margin-top: 60px;
   margin-bottom: 60px;
}

.quote-image-container {
   position: relative;
   width: auto;
   max-width:20%;
   height: 120vh;
   right: 0;
}

.quote-item-2 {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.quote-item-upload-text {
  margin: 30px;
  font-size: 20px;
  text-align: center;
}

.quote-item-upload {
   width: 100%;
   position: relative;
}

.quote-btn-sign-up {
   width: 100%;
}

.quote-tf {
   width: 40%;
   margin-top: 10px;
   margin-right: 10px;
}

.quote-fieldset {
  border-bottom: 1px solid #dedede;
  padding-top: 10px;
  padding-bottom: 40px;
  margin-bottom: 10px;
}

.quote-tf-2 {
   width: 100%;
   margin-top: 15px;
   margin-bottom: 15px;
   margin-right: 10px;
}

.ant-calendar-picker-input {
    height: 46px;
    border-radius: 0px;
}

.ant-dropdown-trigger, .dropdown-item {
  height: 30px;
  background: #fff;
  border: 1px solid #ced4da
}

.quote-upload > .ant-upload.ant-upload-select-picture-card {
   margin-top: 10px;
   width: 100%;
}

.quote-image {
   width: 50%;
}

.quote-smile-guy {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.quote-custom-row.ant-row.ant-form-item {
   width: 48%;
   margin-right:0px;
}

.quote-custom-row-full.ant-row.ant-form-item {
   width: 100%;
}

.quote-autocomplete-row-full {
  width: 50%;
}

.quote-row-full {
  width: 100%;
}


.quote-custom-btn-create-account.ant-btn.ant-btn-primary {
   width: 100%;
}

.quote-address-item {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
}

.quote-bread-winner-title-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.quote-bread-winner-text-title {
  font-size: 20px;
}

.quote-bread-winner-note-title {
  font-size: 11px;
  color: red;
}

@media screen and (max-width: 1024px) and (min-width: 768px)  {
   .quote-custom-btn-create-account-row { width: 50%; }
}

@media screen and (max-width: 1024px) {
   .quote-body { padding-left: 5%; padding-right: 5%; }
}

@media screen and (max-width: 800px) {
  .quote-body-2 { width: 100%; padding-left: 5%; padding-right: 5%; }

}

@media screen and (max-width: 860px) {
  .quote-content-container { padding: 20px; width: 100%; }
  .quote-title {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 1100px) {
   .quote-content-container { margin-top: 30%; }
}

@media screen and (max-width: 600px) {
   .quote-content-container { margin-top: 40%; width: 100% }
}

@media screen and (max-width: 768px) {
   .quote-custom-row.ant-row.ant-form-item{ width: 100%; }
   .quote-image-container{ display: none; }
}

.pg-appintment__title {
  color: #00AC1F; }

.pg-appintment__box {
  margin-top: -30px; }

.quote-row {
  width: 100%;
  display: flex;
  padding: 20px 20px;
  margin: 0px auto;
}

.quote-column1 {

}

.quote-column2 {

}

.quote-column3 {

}

.form-control {
  height: 46px;
  border-radius: 0px;
}

.quote-btn-register {
  margin-right: 10px;
  background-color: #154989;
  border: 1px solid #154989;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  height: 46px;
}

.ant-select-selection {
  height: 46px;
  border-radius: 0px;
}
.ant-select-selection__rendered {
  padding-top: 8px;
  vertical-align: middle;
}

.quote-rate-result-parent {
  width: 100%;
  display: flex;
  flex-direction:column;
  margin-top: 2px;
  margin-bottom: 20px;
}

.qoute-rate-final {
  background: rgba(255,255,255,0.5);
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.quote-amount-parent-box {
  display: flex;
  width: 100%;
}
.quote-amount-left-column {
  flex: 1;
  display: flex;
  flex-direction:row;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 2%;
}

.remit-hope-donation-amount {
  /* flex: 1; */
  display: flex;
  flex-direction:row;
  margin-top: 8px;
  /* margin-bottom: 8px; */
  margin-right: 2%;
}

.quote-amount-right-column {
  flex: 1;
  display: flex;
  flex-direction:row;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 2%;
}

.ant-form-item-label {
  font-size: 16px;
  font-weight: 600;
  color: #3e3e3e;

}

.quote-lines {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  font-size: 24px;
  font-weight: 400;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.quote-lines:before {
  margin-left: -100%;
}
.quote-lines:after {
  margin-right: -100%;
}
.quote-lines:before, .quote-lines:after {
  display: inline-block;
  content: '';
  background-color: #bfbfbf;
  height: 1px;
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  border: solid #FFF;
  border-width: 0 10px;
}


.quote-form--1 {
  width: 100%;
  margin-right: 1%;
}

.quote-form-2 {
  width: 48%;
  margin-left: 1%;

}

.quote-title {
  margin-top: 0px;
  color: white;
  width: 100%;
  text-align: center;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.quote-product-info-text {
  font-weight: 400;
  /* font-family: 'Lato-Bold'; */
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: red;
}

@media screen and (max-width: 479px) {
  .quote-form-1 {
      width: 100%;
      margin: 0px;
  }

  .quote-form-2 {
      width: 100%;
      margin: 0px;
  }
}
