.tr-item-content-container {
   position: relative;
   min-height: 500px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 15%;
   margin-bottom: 10%;
}

.tr-item-body {
   position: relative;
   height: 80vh;
   display: flex;
   justify-content:center;
   align-items:center;
}

.tr-details-body {
   position: relative;
   display: flex;
   flex-direction:column;
   width: 75%;
}

.tr-details-group-body {
   position: relative;
   display: flex;
   flex-direction:column;
   margin-bottom: 20px;
   background: white;
   padding:20px;
}

.tr-details-group-title {
   font-size: 24px;
   color: #3e3e3e;
   font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.tr-details-row-body {
   position: relative;
   display: flex;
   flex-direction:row;
   justify-content: space-between;
   margin-bottom: 20px;
   font-size: 16px;
   flex-wrap: wrap;
}

.tr-details-row-left-body {
  flex: 1;
  margin-right: 100px;
}

.tr-details-row-right-body {
  flex: 1;
  text-align: right;
}

.trl-layout {
   position: relative;
   width:100%;
   min-width: 800px;
   min-height: 800px;
   height: auto;
}

.trl-content-container {
   position: relative;
   width: 100%;
   min-width: 800px;
   min-height: 500px;
   margin-top: 20vh;
   padding: 0;
   background: #fff;
}

.trans-content-container {
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 80%;
  margin-top: 20vh;
  margin-bottom: 10vh;
}

.trl-select-recipient {
   position: relative;
   margin-top: 30px;
   width: 100%;
   text-align: center;
}

.trl-first-last-body {
   position: relative;
   display: flex;
   flex-direction:row;
   align-items:center;
   justify-content:space-around;
   width: 30%;
}

.trans-footer-box-parent {
   position: relative;
   width: 60%;
   margin-top: 30px;
   margin-bottom: 30px;
   margin-left: 20%;
   margin-right: 20%;
   display: flex;
}

.trans-footer-box1 {
    flex: 1;
}

.trans-footer-box2 {
  flex: 1;
}

.trans-footer-box3 {
  flex: 1;
}

.trans-footer-box4 {
  flex: 1;
}

.trans-footer-box5 {
  flex: 1;
}

.tr-error-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tr-error-title {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.tr-error-tree {
  width: 80%;

}

.tr-error {
  text-align: center;
  margin-bottom: 8px;
  width: 100%;
}


.trans-body {
   position: relative;
   width: 100%;
   min-height: 500px;
   height: 100%;
   margin-top: 30px;
   padding-left: 10px;
   padding-right: 10px;
}

@media screen and (max-height: 850px) {
   .trl-container { height: 100%; }
}

.zoom-out-layout {
  -moz-transform: scale(0.6, 0.6); /* Moz-browsers */
  zoom: 0.6; /* Other non-webkit browsers */
  zoom: 60%;
}

.zoom-in-layout {
  -moz-transform: scale(1, 1); /* Moz-browsers */
  zoom: 1; /* Other non-webkit browsers */
  zoom: 100%;
}

.trans-layout {
  position: relative;
  width: 100%;
  background: #f2f2f2;
}

.trans-desc-layout {
  position: relative;
  width: 100%;
  background: #f2f2f2;
}

.ant-spin-nested-loading {
  width: 100%;
}

.ant-table-tbody > tr:hover > td {
  background: #f2f2f2;
}

.ant-btn-primary {
  height: 46px;
  font-size: 16px;
  background-color: #0b5ca9;
  border: 1px solid #0b5ca9;
  max-width: 200px;
}

.tn-pagination-box {
	position: relative;
	display: flex;
	margin-top: 2vh;
	width: 100%;
	margin-bottom: 10px;
	justify-content: space-around;

}

.tn-pagination-box-right {
	flex: 1;
}

.tn-pagination-box-middle {
	flex: 4;
	text-align: center;
  margin-right: 8px;
  margin-left: 8px;
}

.tn-pagination-box-left {
	flex: 1;
}

.tn-pagination-box-item {
	margin-left: 0px;
	margin-right: 5px;
	height: 30px;
	min-width: 100px;
	font-size: 16px;
	color: #154989;
	border: 1px solid #154989;
	border-radius: 0px;

}

.trl-p-filter {
	margin-left: 8px;
	background: #154989;
	color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  border: 1px solid #154989;
  border-radius: 4px;
}

.ant-table {
  color: #0a0a0a;
  font-weight: 500;
}

.table-even-row {
  background-color: #DCF3F0;
}

.table-header-row {
  background-color: #3e3e3e;
}

.tr-number-message {
   margin-bottom: 8px;
   text-align: center;
   margin-right: 10vw;
   margin-left: 10vw;
   font-size: 24px;
}

.tr-number-check {
  width: auto;
  object-fit: contain;
  height: 100px;
  margin: 20px;
}

@media screen and (max-width: 900px) {
  .tr-item-content-container {
    margin-top: 35%;
  }
}

@media screen and (max-width: 500px) {
  .tr-item-content-container {
    margin-top: 45%;
  }
  .tr-number-message {
     font-size: 5vw;
  }
}

@media screen and (max-width: 768px) {
  .trl-p-filter {
    margin-right: 8px !important;
  	margin-left: 8px !important;
    margin-top: 0px !important;
  }
}

.trl-point-table .ant-table-thead > tr > th {
  padding: 16px;
  line-height: 16px;
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 700;
  padding: 8px;
}

.trl-point-table .ant-table-tbody > tr > td {
    padding: 16px;
    font-size: 16px;
    font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
    font-style: normal;
    color: #666666;
    font-weight: 400;
}

@media screen and (max-width: 900px) {
  .trl-point-table .ant-table-thead > tr > th {
    padding: 8px;
    font-size: 13px !important;
  }

  .trl-point-table .ant-table-tbody > tr > td {
    padding: 8px;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 768px) {
  .trl-point-table .ant-table-thead > tr > th {
    font-size: 10px !important;
  }

  .trl-point-table .ant-table-tbody > tr > td {
    font-size: 10px !important;
  }

  .trans-content-container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 500px) {
  .trl-point-table .ant-table-thead > tr > th {
    font-size: 9px !important;
  }

  .trl-point-table .ant-table-tbody > tr > td {
    font-size: 9px !important;
  }
}

.search.ant-input-search.ant-input-affix-wrapper {
  width: auto;
  height: 40px;
}
