@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Merriweather);
@import url(https://fonts.googleapis.com/css?family=Didact Gothic&display);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,900&display);
@import url(https://fonts.googleapis.com/css?family=Raleway&display);
@font-face {
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.header-container.ant-layout-header  {
	position: relative;
	background: white;
	width: 100%;
	height: auto;
	line-height: auto;
	display: flex;
	flex-direction: column;
}

.header-up-container1 {
	position: relative;
	background: transparent;
	width: 100%;
	height: 30px;
}

.container2 {
	width: 80%;
	margin-left: 10%;
}


.header {
  position: relative;
	z-index: 999; }

	.header__top {
		height: 43px;
		padding: 8px;
		vertical-align: middle;
	}

.header__top__inner {
  display: -moz-flex;
  display: flex;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -moz-align-items: center;
  align-items: center; }

.header__top__button .cr-btn::before {
  display: none; }

.header__top__button .cr-btn:hover {
  border-color: #00AC1F; }

.header__top__info {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .header__top__info li {
    display: inline-block;
    padding: 0 30px;
    vertical-align: middle;
    line-height: 1; }
    .header__top__info li:not(:last-child) {
      border-right: 1px solid rgba(255, 255, 255, 0.6); }
    .header__top__info li:first-child {
      padding-left: 0; }
    .header__top__info li:last-child {
      padding-right: 0; }
    .header__top__info li a {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
			color: #a4aeb0;
			vertical-align: middle; }

			.header__top__info li span {
				color: #fff;
				font-size: 16px;
				font-weight: 300;
				line-height: 1;
				vertical-align: middle;
			}
      .header__top__info li a i {
        padding-right: 7px; }
      .header__top__info li a:hover {
        color: #00AC1F; }

/* Header Bottom Styles */
.header__bottom__inner {
  display: -moz-flex;
  display: flex;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -moz-align-items: center;
  align-items: center; }

.header__logo a {
  display: inline-block;
	padding: 30px 0;
}

/* Sticky Header */
.sticky--header.is-sticky .header__bottom {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-animation: slideInDown 0.5s ease-in-out 1 both;
  animation: slideInDown 0.5s ease-in-out 1 both;
  -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }

.sticky--header.is-sticky .header__logo a {
  padding: 15px 0; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__top__info li {
    padding: 0 18px; }
  .sticky--header {
    position: relative; } }

@media only screen and (max-width: 767px) {
  .header__top__inner {
    flex-wrap: wrap;
    -moz-justify-content: center;
    justify-content: space-between;
	}
	.header__top__button {
		margin-bottom: 12px;
	}
  .header__top__info {
    margin-bottom: 15px;
    text-align: center; }
    .header__top__info li {
      padding: 0 20px; }
      .header__top__info li:first-child {
        padding-left: 0px; }
      .header__top__info li:last-child {
        padding-right: 20px; }
  .sticky--header {
    position: relative; } }

@media only screen and (max-width: 575px) {
  .mean-container .mean-nav {
    width: 100%; } }


  @media only screen and (max-width: 575px) {
	.mean-container .mean-nav {
		width: 100%; } }

	  .main-navigation {
				display: -moz-flex !important;
				display: flex !important;
				align-self: stretch;
				-moz-align-items: center;
				align-items: center;
}

		.main-navigation ul {
		  display: -moz-flex;
		  display: flex;
		  align-self: stretch;
		  -moz-align-items: center;
		  align-items: center;
		  padding-left: 0;
		  margin-bottom: 0; }
 .main-navigation ul li {
			display: -moz-flex;
			display: flex;
			align-self: stretch;
			-moz-align-items: center;
			align-items: center;
			padding: 0 20px;
	}

	.main-navigation ul li a {
		display: -moz-flex;
		display: flex;
		align-self: stretch;
		-moz-align-items: center;
		align-items: center;
		color: #647072;
		font-weight: 600;
		font-size: 16px;

	}

	  @media only screen and (min-width: 992px) and (max-width: 1199px) {
		.main-navigation ul li {
			padding: 0 17px; }
	}

		.navLink {
			color:rgba(0, 0, 0, 0.85);
		}

		.navLink :hover {
			color: #fff;
		}

		.navLink2 {
			color: #fff;
		}
		.main-navigation ul li button:after,
		.main-navigation ul li a:after {
			content: '';
			display: block;
			height: 1px;
			width: 0px;
			background: transparent;
			-webkit-transition: width 0.5s ease, background-color 0.5s ease;
			transition: width 0.5s ease, background-color 0.5s ease;
		}
		.main-navigation ul li:hover button,
		.main-navigation ul li:hover a {
			text-decoration: none;
		}
		.main-navigation ul li:hover button:after,
		.main-navigation ul li:hover a:after {
			width: 100%;
			background: blue;
		}
/**
Button Styles
*/

a.cr-btn, button.cr-btn, .cr-btn {
	display: inline-block;
	background: rgba(59,172,240);
	padding: 11px 30px;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	vertical-align: middle;
	overflow: hidden;
	border: 1px solid #20407a;
	-webkit-transition: border-color 0.5s ease-in-out 0s;
	transition: border-color 0.5s ease-in-out 0s; }
	a.cr-btn span, button.cr-btn span, .cr-btn span {
	  position: relative;
	  line-height: auto; }
	a.cr-btn:before, button.cr-btn:before, .cr-btn:before {
	  content: "";
	  left: 50%;
	  top: 50%;
	  height: 400px;
	  width: 400px;
	  position: absolute;
	  -webkit-transform: translate(-50%, -50%);
	  transform: translate(-50%, -50%);
	  -webkit-transition: box-shadow 0.5s ease-in-out 0s;
	  -webkit-transition: -webkit-box-shadow 0.5s ease-in-out 0s;
	  transition: box-shadow 0.5s ease-in-out 0s;
	  border-radius: 100%; }
	a.cr-btn:hover, button.cr-btn:hover, .cr-btn:hover {
	  color: #fff;
	  border-color: #333982; }
	  a.cr-btn:hover:before, button.cr-btn:hover:before, .cr-btn:hover:before {
		-ms-box-shadow: inset 0 0 0 400px #333982;
		-o-box-shadow: inset 0 0 0 400px #333982;
		box-shadow: inset 0 0 0 400px #333982; }
	a.cr-btn.cr-btn--lg, button.cr-btn.cr-btn--lg, .cr-btn.cr-btn--lg {
	  padding: 12px 30px; }
	a.cr-btn.cr-btn--sm, button.cr-btn.cr-btn--sm, .cr-btn.cr-btn--sm {
	  padding: 8px 40px; }
	a.cr-btn.cr-btn--transparent, button.cr-btn.cr-btn--transparent, .cr-btn.cr-btn--transparent {
	  font-weight: 600;
	  font-size: 16px;
	  color: #647072;
	  background: transparent;
	  border-color: #9f9f9f; }
	  a.cr-btn.cr-btn--transparent:hover, button.cr-btn.cr-btn--transparent:hover, .cr-btn.cr-btn--transparent:hover {
		border-color: #00AC1F;
		color: #fff; }
		a.cr-btn.cr-btn--transparent:hover:before, button.cr-btn.cr-btn--transparent:hover:before, .cr-btn.cr-btn--transparent:hover:before {
		  -ms-box-shadow: inset 0 0 0 400px #00AC1F;
		  -o-box-shadow: inset 0 0 0 400px #00AC1F;
		  box-shadow: inset 0 0 0 400px #00AC1F; }

  /* Readmore Buttons */
  .cr-readmore, a.cr-readmore, button.cr-readmore {
	display: inline-block;
	border: 1px solid #00AC1F;
	padding: 3px 18px;
	color: #00AC1F; }

  .cr-readmore-2, a.cr-readmore-2, button.cr-readmore-2 {
	display: inline-block;
	color: #00AC1F;
	font-weight: 500; }
	.cr-readmore-2::after, a.cr-readmore-2::after, button.cr-readmore-2::after {
	  content: "\f101";
	  font-family: 'fontAwesome';
	  padding-left: 5px;
	  -webkit-transition: all 0.3s ease-in-out 0s;
	  transition: all 0.3s ease-in-out 0s; }
	.cr-readmore-2:hover, a.cr-readmore-2:hover, button.cr-readmore-2:hover {
	  color: #00AC1F; }
	  .cr-readmore-2:hover::after, a.cr-readmore-2:hover::after, button.cr-readmore-2:hover::after {
		padding-left: 10px; }

  a.video-btn, button.video-btn, .video-btn {
	display: inline-block;
	height: 60px;
	width: 60px;
	text-align: center;
	border-radius: 100px;
	background: #00AC1F;
	color: #ffffff;
	line-height: 1;
	font-size: 28px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s; }
	a.video-btn i, button.video-btn i, .video-btn i {
	  line-height: 60px;
	  padding-left: 5px; }
	a.video-btn:hover, button.video-btn:hover, .video-btn:hover {
	  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

.header-low-container1 {
	position: relative;
	background: transparent;
	width: 100%;
	display: flex;
	flex-direction:row;
	align-items:center;
	justify-content: space-between;
	padding: 20px;
}

.bg--theme {
	background: #00AC1F; }

  .bg--secondary {
	background: #333982; }

  .bg--blue {
	background: #0b5ca9; }

  .bg--green {
	background: #154989; }

  .bg--yellow {
	background: #EFC418; }

  .bg--orange {
	background: #EF785A; }

  .bg--light {
	background: #999999; }

  .bg--dark {
	background: #222222; }

  .bg--dark--light {
	background: #2a2a2a; }

  .bg--black {
	background: #000000; }

  .bg--white {
	background: #ffffff; }

  .bg--grey {
	background: #fafafa; }

  .bg--grey--light {
	background: #f2f2f2; }

.header-welcome {
	position: absolute;
	right: 13vw;
	color: white;
	top: 5px;
}

.header-welcome-text {
	font-size: 8px
}

.header-logo1 {
	margin-left: 10px;
	max-height: 60px;
	margin: 0;
	padding: 0;
}

.header-left-parent1 {
	position: relative;
	width: 20%;
	margin-left: 10vw;
	padding: 0;
}

.header-social-media {
	position: relative;
	display: flex;
	flex-direction:row;
	justify-content:center;
}

.header-right-parent1 {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-right: 9.5vw;
}

.header-right {
	position: relative;
	right: 0;
	display: flex;
	flex-direction: row;
}

.header-button {
	background-color: Transparent;
  color: black;
  font-size: .9em;
  margin-bottom: 0px;
	max-width: 70px;
}

.header-button-selected {
	background-color: Transparent;
  color: white;
  font-size: .9em;
  margin-bottom: 0px;
}

.header-button-parent-selected {
	background: #E82732;
	height: 100%;
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	justify-content: center;
	align-items: center;
}

.header-sign-up1 {
	color: rgba(255, 255, 255, .7);
	margin-right: 25px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: .9em;
}

.header-login1 {
	color: rgba(255, 255, 255, .7);
	margin-right: 25px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: .9em;
}

.header-social-logo {
	height: 100%;
	display: flex;
	flex-direction:row;
	text-align: center;
}

.header-social-logo-icon {
	height: 30px;
	margin-left: 8px;
}


.header-mobile-top-up {
	margin-right: 20px;
}

.drawer {
	display: none
}

.drawer-button.ant-btn {
	display: none !important;
}

.drawer-button-logged-in.ant-btn {
	display: none !important;
}

.drawer-close.ant-btn {
  border: 1px solid transparent;
  height: 50px;
  width: 50px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: white;
}

@media screen and (max-width: 1024px) {
   .drawer-button.ant-btn  {
     top:30px;
     left: 0;
     position: absolute;
     display: inline !important;
     border-top-right-radius: 4px;
     border-bottom-right-radius: 4px;
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;
     border-left-color: transparent;
   }

   .drawer-button-logged-in.ant-btn  {
     top: 75px;
     left: 0;
     position: absolute;
     display: inline !important;
     border-top-right-radius: 4px;
     border-bottom-right-radius: 4px;
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;
     border-left-color: transparent;
   }

   .drawer { display: inline; display: initial; }
   .header-right-parent { display: none; }
   .header-button { color: #666666; }
	 .header-menu-container { display: none; }
	 .navigation-inner-right { display: none;}
	 .navigation-inner-middle { display: none;}
	 .navigation-inner2 { display: flex; align-items: center; align-content: center}
	 .navigation-inner-left { display: inline-block; }
   .header-left-parent {margin-left: 10vw;}
   .header-low-container1 { flex-direction: column; padding-left: 0; padding-right: 0;}
   .header-left-parent1 { width: 80%; margin-left: 0; display: flex; justify-content: center;}
   .header-right-parent1 { flex-direction: column; width: 100%; margin-top: 20px; margin-right: 0; padding-left: 15vw; padding-right: 15vw;}
   .header-welcome { position: relative; width: 100%; text-align: center; right: 0; left: 0;}
   .header-social-media { margin-top: 8px;}
	 .header-up-container1 { height: 60px; display: flex; align-items: center;	justify-content: center;}
	 .header-logo1 {
	 		max-height: 40px;
	 }
}

@media screen and (max-width: 900px) {
   .header-left-parent {margin-left: 10vw;}
   .header-right-parent {margin-right:20px;}
   .header-button { margin-right: 10px;}
   .container2 {
     margin-left: 15%;
   }
}

@media screen and (max-height: 700px) {
  .navigation ul .list-item button,
  .navigation ul .list-item a {
    font-size: 11.5px !important;
    margin: 4px !important;
  }
  .navigation ul .list-button-signup {
    font-size: 11.5px !important;
  }
  .navigation ul .list-button-signup a {
    font-size: 11.5px !important;
    margin: 4px !important;
    width: 80px !important;
    height: 35px !important;
  }
}

.navigation {
  background: white; /*linear-gradient(135deg, #003d6d 79%, #00568b 40%, #00568b 100%);*/
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 6px 6px 0 rgba(0, 0, 0, 0.2);
  margin-top: 0px;
	height: 100px;
	vertical-align: middle;
}

.navigation-inner {
	display: flex;
	width: 100%;
}

.navigation-inner2 {
	display: none;
	width: 100%;
}

.navigation-inner-left {

}

.navigation-inner-middle {
	flex: 3 1;
	padding: 15px 0px;
}

.navigation-inner-middle2 {


}

.navigation-inner-right {
	padding: 10px 0px;
	text-align: right;
	align-items: right;
	align-content: right;
}

.navigation-inner-mobile {
	width: 100%;
	padding: 5px;
	align-content: center;
	flex: 1 1;
}
.navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.navigation ul .list-item {
  display: inline-block;
  font-size: 10px;
  vertical-align: middle;
}
.navigation ul .list-item:first-child {
  margin-top: 0;
}
.navigation ul .list-item button,

.navigation ul .list-item a {
  display: inline-block;
  font-size: 15px;
  color: #647072;
  padding: 0;
  margin-left: 28px;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-right: 0px;
	padding-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
	font-family: 'Rubik', Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}
.navigation ul .list-item button:after,
.navigation ul .list-item a:after {
  content: '';
  display: block;
  margin: 5px auto auto auto;
  height: 3px;
  width: 0px;
  background: #154989;;
  -webkit-transition: width 0.5s ease, background-color 0.5s ease;;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.navigation ul .list-item.current-section button:after,
.navigation ul .list-item.current-section a:after {
  content: '';
  display: block;
  margin: 5px auto auto auto;
  height: 3px;
  width: 100%;
  background: #154989;
  -webkit-transition: width 0.5s ease, background-color 0.5s ease;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.navigation ul .list-item:hover button,
.navigation ul .list-item:hover a {
  text-decoration: none;
}
.navigation ul .list-item:hover button:after,
.navigation ul .list-item:hover a:after {
  width: 100%;
  background: #154989;
}

.navigation ul .list-button-login {
  display: inline-block;
  font-size: 16px;
	color: #647072;
  vertical-align: middle;
}

.navigation ul .list-button-login:first-child {
  margin-top: 0;
}
.navigation ul .list-button-login button,

.navigation ul .list-button-login a {
	background-color: #154989;
	border: 1px solid #154989;
  display: inline-block;
  font-size: 16px;
  color: #fff;
	height: 40px;
	border-radius: 4px;
	text-align: center;
	text-decoration: none;
	width: 120px;

  padding: 0;
  margin: 15px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
}

.navigation ul .list-button-login:hover button,
.navigation ul .list-button-login:hover a {
	text-decoration: none;
	background-color: #fff;
	color: #3e3e3e;
}
.navigation ul .list-button-login:hover button:after,
.navigation ul .list-button-login:hover a:after {
  width: 100%;
  background: #fff;
}

.navigation ul .list-button-signup {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
}

.navigation ul .list-button-signup:first-child {
  margin-top: 0;
}
.navigation ul .list-button-signup button,

.navigation ul .list-button-signup a {
	background-color: transparent;
	border: 1.5px solid #154989;
  display: inline-block;
  font-size: 16px;
  color: #647072;
	height: 40px;
	border-radius: 20px;
	text-align: center;
	text-decoration: none;
	width: 120px;
  padding: 0;
  margin: 15px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
}

.navigation ul .list-button-signup:hover button,
.navigation ul .list-button-signup:hover a {
	text-decoration: none;
	background-color: #154989;
	color: #fff;
}
.navigation ul .list-button-signup:hover button:after,
.navigation ul .list-button-signup:hover a:after {
  width: 100%;
  background: #154989;
}

.mobile-number .ant-input-group .ant-input {
  height: 46px;
  border-radius: 0px;
}

.user-container {
   position: relative;
   width:100%;
   height: 100vh;
}

.user-container-2 {
   position: relative;
   width:100%;
   height: 100vh;
   margin: 0;
}

.user-layout {
   position: relative;
   width:100%;
}

.user-anchor.ant-anchor-wrapper {
  position: absolute;
  background-color:rgba(255, 255, 255, 0.4);
  margin-top:60px;
  margin-left:20px;
  margin-right: 0;
  padding:8px;
}

.user-content-container {
   position: relative;
   width: 100%;
   margin-bottom: 80px;
   display: flex;
   flex-direction:column;
}

.user-wrap-content-container {
   position: relative;
   display: flex;
   flex-direction:column;
   align-items: center;
   width: 60%;
   align-self: center;
}

.user-content-container-2 {
   position: relative;
   margin-left: 30%;
   margin-right: 30%;
   display: flex;
   flex-direction: column;
   align-items:center;
   justify-content: center;
   height: auto;
   margin-top: 10%;
   margin-bottom: 5%;
   background: white;
}

.user-alert {
   margin-top: 20px;
   text-align: center;
}

.user-saved-cards-body {
   position: relative;
   width: 100%;
   height: 100%;
   padding-top: 20px;
   padding-bottom: 30px;
}

.fica-box {
  position: relative;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 80%;
  margin-top: 200px;
  background: #f2f2f2;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}

.user-document-management-body {
   position: relative;
   width: 100%;
   height: 100%;
   padding-top: 30px;
}

.user-saved-cards-item {
   background: #FFFFFF;
}

.user-body.ant-form.ant-form-horizontal {
   position: relative;
   width:100%;
   height: 100%;
}

.documents-box {
  position: relative;
  height: 100%;
  width: 80%;
  margin-left: 10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  padding-bottom: 30px;
  background: #f2f2f2;
}

.documents-box-form {
  padding-left: 20%;
  padding-right: 20%;

}

.profile-body.ant-form.ant-form-horizontal {
  position: relative;
  align-content: center;
  align-items: center;
  margin-left: 20%;
  width:60%;
  height: 100%;
}

.user-password-menu {
  position: relative;
  margin: auto;
  width: 100%;
}

.user-password-menu-item-text {
  text-align: center;
}

.user-password-menu-item {
  width: 50%;
}

.user-password-body.ant-form.ant-form-horizontal {
  background: white;
  position: relative;
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction:column;
  justify-content:center;
  padding: 40px;
}

.user-item-2 {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.user-phone-verification {
   position: relative;
   display: flex;
   width: 100%;
   justify-content: center;
}
.user-phone-child {
   position: relative;
   flex-direction: row;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}

.user-phone-left-button {
  margin-right: 8px;
}


.user-tf {
   width: 40%;
   margin-top: 10px;
   margin-right: 10px;
}

.user-tf-2 {
   width: 100%;
   margin-top: 10px;
   margin-right: 10px;
}

.user-custom-row.ant-row.ant-form-item {
   width: 48%;
   margin-right:8px;
}

.user-custom-row-full.ant-row.ant-form-item {
   width: 100%;
}

.user-custom-btn-row.ant-row.ant-form-item {
   width: 100%;
   padding: 11.5px;
   height: auto;
   border-radius: 0;
}

.user-custom-btn.ant-btn.ant-btn-primary {
   width: 100%;
   padding: 11.5px;
   height: auto;
   border-radius: 0;
   background: #154989;
   border: 1px solid #154989;
   margin: 8px;
}

.user-title {
   position: relative;
   padding-top: 30px;
   width: 100%;
   text-align: left;
   font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.user-selfie-desc {
  margin-bottom: 30px;
}

.user-reset-password-result-text {
   margin-bottom: 20px;
}

.user-fica-parent {
   display: flex;
   flex-direction:column;
   align-items:center;
}

.user-fica-text {
   margin-top: 8px;
   margin-bottom: 8px;
   text-align: center;
}

.user-fica-note-text {
   margin-top: 20px;
   margin-bottom: 20px;
   text-align: center;
   font-size: 13px;
}

.user-fica-limit-text {
   margin-top: 40px;
   margin-bottom: 40px;
   text-align: center;
}

.user-fica-parent-details {
   display: flex;
   flex-direction:row;
   justify-content: space-between;
   flex-wrap: wrap;
   margin-left: 10vw;
   margin-right: 10vw;
}

.user-fica-parent-child-details {
   display: flex;
   flex-direction:column;
}


@media screen and (max-width: 768px) {
   .user-content-container-2 { margin-left: 5%; margin-right: 5%; margin-top: 30%; }
}

@media screen and (max-height: 500px){
   .user-container-2 { height: 100% }
}

@media screen and (min-width: 320px){
   .user-container-2 { height: 100% }
}

@media screen and (max-width: 768px) {
   .user-custom-row.ant-row.ant-form-item{ width: 100%; }
   .user-wrap-content-container { width: 80%; }
}

@media screen and (max-width: 1024px) {
   .user-anchor.ant-anchor-wrapper { display: none }
}

.user-layout {
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 2000px) {
  .userprofile-content-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: left;
    background: #fff;
    border-radius: 0px;
    padding: 20px;
    width: 80%;
    margin-top: 28vh;
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .userprofile-content-container {
    position: relative;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: left;
    background: #fff;
    border-radius: 0px;
    padding: 0px;
    width: 80%;
    margin-top: 28vh;
    margin-bottom: 10%;
  }
}


.user-limit-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: left;
  background: -webkit-gradient(linear, left top, right top, from(rgb(41, 108, 187)), to(#154989));
  background: -webkit-linear-gradient(left, rgb(41, 108, 187), #154989);
  background: linear-gradient(to right, rgb(41, 108, 187), #154989);
  border-radius: 0px;
  padding: 20px;
  width: 100%;
}

.ant-layout {
  background-color: #fff;
}

.profile-tf-2 {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.profile-box {
  display: flex;
  margin-left: 0px;
  padding: 20px;
  overflow: scroll;
  flex-wrap: wrap;
}

.profile-box-right {
  flex: 3 1;
  margin-right: 10px;
  margin-left: 10px;
}
.profile-box-center {
  flex: 1 1;
  }

.profile-box-left {
  flex: 3 1;
  width: 100%;
}

.ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #0b5ca9;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .userprofile-content-container {
    margin-top: 30vh;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 100%;
  }
  .profile-box-center {
    flex: 0 1;
  }
  .profile-box {
    padding: 0;
  }
  .profile-body.ant-form.ant-form-horizontal {
    width: 100%;
    margin-left: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
  .user-body.ant-form.ant-form-horizontal { padding-left: 5%; padding-right: 5%;}
  .documents-box {
    margin-left: 0;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
  }
  .documents-box-form {
    padding-left: 5%;
    padding-right: 5%;
  }
  .fica-box {
    position: relative;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: auto;
    width: 100%;
  }

}

@media screen and (max-width: 500px) {
  .userprofile-content-container {
    margin-top: 35vh;
    margin-left: 3vw;
    margin-right: 3vw;
    width: 100%;
  }
  .user-fica-parent-details {
    justify-content: center;
    margin-top: 40px;
  }
}

.footer-container {
	background: #46c2cf;
	width: 100%;
	bottom: 0;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.footer-get-the-app {
	margin-top: 30px;
	margin-bottom: 0;
	color: white;
}

.footer-send-money {
	margin-top: 20px;
	margin-left: 3%;
	margin-right: 3%;
	color: white;
}

.footer-apps {
	margin-top: 30px;
}

.footer-apps-logo {
	margin: 8px;
	width: 10%;
	min-width: 150px;
}

.footer-social-logo {
	display: flex;
	flex-direction:row;
	text-align: center;
	justify-content: center;
}

.footer-social-logo-icon {
	height: 30px;
	margin-left: 8px;
}

.footer-parent-info-area {
	position: relative;
	display: flex;
	justify-content:center;
}

.footer-sub-parent-info-area {
	position: relative;
	display: flex;
	flex-direction:row;
	width: 80%;
	justify-content:space-around;
	margin-top: 30px;
}

.footer-info-area {
	position: relative;
	display: flex;
	flex-direction:column;
	color: white;
}

.footer-info-title {
	color: white;
}

.footer-info-text-divider {
	margin-bottom: 8px;
	color: #154989;
	background-color: #154989;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
	font-size: 18px;
}

.footer-info-text {
	margin-bottom: 8px;
	color: #154989;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
	font-size: 18px;
}

.footer-divider.ant-divider.ant-divider-horizontal {
	background: #154989;
}

.footer-address-area {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	margin-bottom: 20px;
}

.footer-address-text {
	color: #154989;
	font-size: 14px;
	margin: 0 300px;
}

.footer-parent-copyright-area {
	position: relative;
	display: flex;
	justify-content:center;
}

.footer-copyright-area {
	position: relative;
	display: flex;
	flex-direction:row;
	flex-wrap: wrap;
	width: 50%;
	min-width: 300px;
	justify-content:space-around;
	color: #154989;
	align-items:center;
}

.footer-copyright-text {
	margin-right: 8px;
	color: #154989;
}

@media screen and (max-width: 1024px) {
	.footer-address-text {
		font-size: 14px;
		margin: 0 200px;
	}
}

@media screen and (max-width: 768px) {
	.footer-address-text {
		font-size: 14px;
		margin: 0 100px;
	}
}

@media screen and (max-width: 500px) {
	.footer-address-text {
		font-size: 12px;
		margin: 0 20;
	}

	.footer-info-text {
		font-size: 16px;
	}
}

body {
  color: #777;
  font-family: Helvetica, "Didact Gothic", "Poppins", Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.625em;
  position: relative;
}
h1,
h2 {
  font-weight: 400;
  line-height: 1.25;
}

h1 {
  font-size: 2.4rem;
}
h1,
h2,
h3,
h4 {
  margin: 0 0 16px;
  text-transform: none;
}
.html2canvas-container {
  width: 3000px !important;
  height: 3000px !important;
}
.home-container {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

.home-top-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: black;
}

.home-top-bg-carousel {
  height: 100vh;
}

.home-top-bg-1 {
  background-image: url(/static/media/sasai-remit-banner.6a5c7ea5.png);
  background-size: cover;
  background-position: center center;
  align-items: center;
  justify-content: center;
  height: 88vh;
}

.home-top-bg-1-child {
  /* background-image: url(../images/two-people-pins.png); */
}

.home-top-bg-2 {
  background-image: url(/static/media/EcoCash-Remit-Banner-2.8ae9bff5.png);
  background-size: cover;
  background-position: center center;
  align-items: center;
  justify-content: center;
  height: 88vh;
}

.home-top-bg-4 {
  background-image: url(/static/media/NCEA-website-banner.230a5386.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 40px;
  align-items: center;
  justify-content: center;
  height: 88vh;
}

.home-parent-body {
  width: 80%;
  height: 100%;
  position: absolute;
  display: flex;
}

.home-middle-container-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  height: 60vh;
  padding-left: 10%;
  padding-right: 10%;
}

.home-middle-container {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-payment-container {
  width: 100%;
  position: relative;
  background-color: #f7f7f7;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
}

.home-payment-options-container {
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.home-last-sub-logo-container {
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}

.home-visa-master-logo {
  max-width: 578px;
  height: auto;
  margin-left: 4px;
  margin-right: 4px;
  object-fit: contain;
}

.home-last-sub-logo {
  width: 150px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: contain;
}

.home-last-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.home-stories-container {
  position: relative;
  display: flex;
  width: 30vw;
  height: 100%;
  min-height: 300px;
  flex-direction: column;
  margin-right: 20px;
  padding: 20px;
}

.home-stories-img {
  background-image: url(/static/media/mother-daughter.84166f09.jpg);
  background-size: cover;
  background-position: center center;
  height: auto;
  width: 30%;
}

.home-divider {
  position: relative;
  width: 85%;
  height: 1px;
  background-color: #666666;
  margin-top: 8px;
  margin-bottom: 8px;
}

.home-content-container2.ant-layout-content {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.home-layout {
  position: relative;
  width: 100%;
  background: transparent;
}

.home-layout-auto {
  width: 100%;
  height: 100%;
}

.home-calculate-layout.ant-layout {
  position: relative;
  width: 100%;
  height: auto;
  background: -webkit-gradient(linear, left top, right top, from(#0c5ca9), to(#57a6d0));
  background: -webkit-linear-gradient(left, #0c5ca9, #57a6d0);
  background: linear-gradient(to right, #0c5ca9, #57a6d0);
}

.home-image-container {
  position: relative;
  width: 100%;
  top: 0;
  bottom: 0;
}

.home-left-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-left-parent {
  flex-direction: column;
  flex: 1 1;
}

.home-right-parent {
  flex: 1 1;
}

.home-left-text {
  color: white;
}

.home-head-right-text {
  color: white;
  text-align: center;
}

.home-right-text {
  color: #676767;
}

.home-right-text-get {
  color: #20409a;
  font-size: 20px;
}

.home-right-text-transfer-fees {
  color: #20409a;
  font-size: 15px;
}

.home-right-text-area-2 {
  position: relative;
  display: flex;
  flex-direction: column;
}

.home-right-top-parent {
  position: relative;
  width: 100%;
}

.home-right-discount-icon {
  height: 10vh;
}

.home-right-discount-area {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
}

.home-right-offer-area {
  position: relative;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5vh;
  opacity: 0.7;
}

.home-rate {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.home-right-text-area {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 40%;
  padding: 40px;
  margin-bottom: 5%;
  justify-content: space-around;
}

.home-right-desc {
  display: none;
}

.home-right-desc-text {
}

.home-left-text-area {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 40%;
  padding: 40px;
  top: 20%;
  justify-content: space-around;
}

.home-content-container.ant-layout-content {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
}

.home-body.ant-form {
  position: relative;
  width: 100%;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-btn-calculate {
  left: 0;
}

.home-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.home-custom-row.ant-row.ant-form-item {
  width: 100%;
}

.custom-d.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  width: 5vh;
}

.custom-header.ant-layout-header {
  padding: 0;
  margin: 0;
  line-height: normal;
  background: transparent;
}

.custom-header2.ant-layout-header {
  padding: 0;
  height: auto;
  margin: 0;
  line-height: normal;
  background: transparent;
  position: fixed;
}

/*.quote-custom-footer.ant-layout-footer {
   padding: 0;
   position: relative;
   bottom: 0;
}*/

.custom-footer.ant-layout-footer {
  padding: 0;
  width: 100%;
  bottom: 0;
}

.home-collect.ant-btn {
  padding: 11.5px 32px;
  height: auto;
  border-radius: 0;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  border: 1px solid #154989;
}

.home-mobile-store.ant-btn {
  padding: 11.5px 32px;
  height: auto;
  border-radius: 0;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  background: #46c2cF;
  border: 1px solid #46c2cF;
  color: white;
  letter-spacing: 2px;
  font-size: 16px;
  margin: 8px;
}

.dropdown.ant-btn {
  width: 40vh;
}

.home-custom-dropdown {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}

.dropdown-item.ant-dropdown-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.home-currency-amount-area {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.home-currency-amount-area {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.home-quote {
  margin-top: 40px;
  margin-bottom: 20px;
}

.flag-country {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flag-country-name {
  margin-left: 8px;
}

.home-banner-carousel {
  margin-top: 60px;
}

.home-top-carousel .ant-carousel .slick-slider {
  height: 100%;
}

.home-top-carousel .ant-carousel .slick-slide {
  text-align: center;
  width: 100%;
  height: 100%;
  line-height: 100px;
  overflow: hidden;
}

.home-top-carousel .ant-carousel .slick-slide h3 {
  color: #fff;
}

.home-top-title {
  color: #154989;
  text-align: left;
  width: 100%;
  /* font-size: 70px; */
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: 0;
  /* font-family: 'Oswald', sans-serif; */
  font-family: "Rubik", sans-serif;
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
}

.home-top-title-green {
  /* color : rgb(48, 87, 30); */
  color: white;
  text-align: left;
  width: 100%;
  /* font-size: 88px; */
  font-size: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  /* font-family: 'Oswald', sans-serif; */
  font-family: "Rubik", sans-serif;
  line-height: 110px;
  font-style: normal;
}

@media screen and (max-height: 700px) {
  .home-top-title-green {
    font-size: 13vh;
    line-height: 13vh;
  }
}

.home-top-desc {
  color: #154989;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  line-height: 24px;
  /* font-family: 'Merriweather', Arial, sans-serif; */
  font-family: "Raleway", "Merriweather", Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  /* font-style: italic; */
}

.home-top-desc-green {
  /* color : rgb(78, 140, 50); */
  /* color : rgb(48, 87, 30); */
  color: #154989;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  /* font-family: 'Merriweather', sans-serif; */
  font-family: "Raleway", "Merriweather", Arial, sans-serif;
  line-height: 24px;
  font-size: 24px;
  font-weight: 400;
  /* font-style: italic; */
}

.home-top-ecocash {
  text-align: left;
  color: white;
  font-weight: 700;
  letter-spacing: 8px;
  font-size: 18px;
  font-family: BentonSans, "Rubik", "Lato", sans-serif;
  line-height: 21px;
}

.home-top-ecocash-green {
  text-align: left;
  color: rgb(100, 100, 100);
  font-weight: 700;
  letter-spacing: 8px;
  font-size: 18px;
  font-family: BentonSans, "Rubik", "Lato", sans-serif;
  line-height: 21px;
}

.home-partner-list {
  display: flex;
  overflow: scroll;
  flex-direction: row;
  justify-content: space-between;
  padding: 3%;
}

.home-partner-logo-start {
  object-fit: contain;
}

.home-partner-logo {
  margin-left: 8vw;
  object-fit: contain;
}

.home-collect-area {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50vh;
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  font-weight: "400";
}

.home-collect-area-middle {
  margin-top: 20px;
  margin-bottom: 40px;
  line-height: 1.5;
  text-align: center;
}

.home-choose-area {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 80px;
}

.home-choose-icons-area {
  width: 80%;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
}

.home-choose-icons-child-area {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.home-choose-icon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /* border: 5px solid #154989; */
}

@media screen and (max-width: 768px) {
  .home-content-container {
    margin-left: 30%;
    margin-right: 30%;
  }
  .quote-body.ant-form {
    width: 80%;
    padding: 6%;
    margin: 3vh;
  }
  .home-top-container {
    width: 100%;
    height: 120vh;
  }
  /*.ant-carousel { display : none }*/
  .home-item {
    padding-left: 3%;
    padding-right: 3%;
  }
}

.home-mid-video {
  display: block;
}

@media screen and (max-height: 400px) {
  .home-top-container {
    height: 200vh;
  }
}

@media screen and (max-width: 1024px) {
  .home-custom-row.ant-row.ant-form-item {
    width: 100%;
  }
  .home-left-text-area {
    top: 10%;
  }

  .home-last-container {
    display: none;
  }

  .home-layout-auto {
    background-size: cover;
    background-position: center center;
  }
  .home-top-title {
    font-size: 5vh;
  }
  .home-top-desc {
    font-size: 16px;
  }
  .home-top-title-green {
    font-size: 5vh;
    line-height: 8vh;
  }
  .home-top-desc-green {
    font-size: 16px;
  }
  .home-top-ecocash {
    font-size: 15px;
    letter-spacing: 6px;
  }
  .home-top-ecocash-green {
    font-size: 15px;
    letter-spacing: 6px;
  }
}

@media screen and (max-height: 800px) {
  /* .home-top-title {
    font-size : 13px;
  } */

  .home-top-desc {
    font-size: 16px;
  }
  .home-top-desc-green {
    font-size: 16px;
  }

  .home-top-ecocash {
    font-size: 15px;
    letter-spacing: 4px;
  }
  .home-top-ecocash-green {
    font-size: 15px;
    letter-spacing: 4px;
  }
}

@media screen and (max-width: 700px) {
  /* .home-top-title {
    font-size : 13px;
  } */

  .home-top-desc {
    font-size: 13px;
  }
  .home-top-desc-green {
    font-size: 13px;
  }

  .home-top-ecocash {
    font-size: 9px;
    letter-spacing: 4px;
  }
  .home-top-ecocash-green {
    font-size: 9px;
    letter-spacing: 4px;
  }
}

@media screen and (max-width: 800px) {
  .home-content-container2.ant-layout-content {
    flex-direction: column;
  }
  .home-right-text-area {
    width: 80%;
    margin-top: auto;
    margin-left: 10%;
    background-color: #f0f2f5;
    padding-bottom: 20px;

    border-radius: 8px;
  }
  .home-left-parent {
    width: 100%;
    right: 0;
    margin-bottom: 0;
    margin-top: 8%;
  }
  .home-body.ant-form {
    width: 100%;
    right: 0;
  }

  .home-right-parent {
    display: none;
  }
  .home-right-desc {
    display: block;
    text-align: center;
  }
  .home-right-desc-text {
    font-size: 10px;
  }
  .home-right-parent {
    width: 90%;
  }

  .home-content-container.ant-layout-content {
    margin-left: 5%;
    margin-right: 5%;
  }

  .home-payment-options-container {
    padding: 0;
    border-width: 4px;
  }

  .home-last-sub-logo-container {
    width: 80%;
  }

  .home-visa-master-logo {
    width: 200px;
    height: 80px;
  }

  .home-last-sub-logo {
    width: 100px;
    height: 40px;
  }
  .home-mid-video {
    display: none;
  }
  .home-app-area-sub-title-area {
    width: 80%;
  }
  .home-app-area {
    min-height: 80vh !important;
  }
  .home-collect-area {
    height: 80vh !important;
  }
  .home-right-text-area {
    top: 10%;
  }
  .home-body.ant-form {
    position: relative;
  }
  .container-right-image {
    display: none !important;
  }
  .containers {
    margin-top: 20%;
    width: 70% !important;
  }
}

.gen-error-img {
  width: 300px;
  height: 300px;
}

.gen-error-parent {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quote-limit-text {
  color: #b6b6b4;
  font-size: 10px;
  margin-bottom: 8px;
}

.quote-currency-text {
  font-size: 13px;
  margin-bottom: 8px;
}

.container-right-image {
  object-fit: contain;
  height: 65vh;
  width: 65vw;
  margin-top: 10%;
  margin-right: 5%;
}

@media screen and (max-height: 900px) {
  .home-right-text-area {
    padding: 5px;
    width: 90%;
    margin-top: 10%;
    top: 0;
  }

  .home-right-text-area {
    position: relative;
  }
}

@media screen and (max-width: 1024px) {
  .container-right-image {
    margin-top: 20%;
    height: 40vh;
    width: 40vw;
  }
}

@media screen and (min-width: 801px) {
  .home-right-text-area {
    top: 25%;
  }
}

@media screen and (max-width: 600px) {
  .home-payment-options-container {
    width: 80%;
  }
}

@media screen and (max-height: 600px) {
  .home-right-text-area {
    padding: 5px;
    width: 90%;
    margin-top: 10%;
    top: 0;
  }

  .home-right-text-area {
    position: relative;
  }
}

@media screen and (min-height: 560px) {
  .home-layout {
    height: 100%;
  }

  .home-calculate-layout.ant-layout {
    height: 100%;
  }
}

.home-main-container {
  display: flex;
}

.getStarted--button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid rgba(59, 172, 240);
  border-radius: 5px;
  color: rgba(59, 172, 240);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
  padding: 11px 16px 8px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  -webkit-transition: background-color 0.25s linear, border-color 0.25s linear,
    color 0.25s linear;
  transition: background-color 0.25s linear, border-color 0.25s linear,
    color 0.25s linear;
  vertical-align: middle;
  white-space: nowrap;
}

.www-Button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  width: 200px;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color 0.25s ease, border 0.25s ease, color 0.25s ease;
  transition: background-color 0.25s ease, border 0.25s ease, color 0.25s ease;
  vertical-align: middle;
  white-space: nowrap;
}
.www-Button--primaryInverse {
  background-color: #fff;
  border: none;
  color: rgba(59, 172, 240);
}

.bui-u-mt--medium {
  margin-top: 24px !important;
}

.Text--regular {
  font-size: 20px;
}

.Opacity--80 {
  opacity: 0.8;
}
.bui-u-mb--large {
  margin-bottom: 32px !important;
}
.bui-u-textWhite {
  color: #fff !important;
}

.Home-heroHeader {
  font-size: 50px;
  letter-spacing: -1.55px;
  line-height: 1.18;
}

.bui-u-mb--regular {
  margin-bottom: 16px !important;
}
.bui-u-textWhite {
  color: #fff !important;
}
.relative {
  position: relative;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.banner-area .overlay-bg {
  background-color: rgba(59, 172, 240, 0.4);
}
.banner-content {
  text-align: left;
}
.banner-content h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1em;
  margin-top: 20px;
}
.banner-content h1 span {
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .banner-content h1 {
    font-size: 36px;
  }
}
@media (max-width: 991.98px) {
  .banner-content h1 br {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .banner-content h1 {
    font-size: 45px;
  }
}
@media (max-width: 414px) {
  .banner-content h1 {
    font-size: 32px;
  }
}
@media (max-width: 1280px) {
  .banner-content br {
    display: none;
  }
}
@media (max-width: 763px) {
  .banner-content p {
    color: #fff;
  }
  .banner-area {
    margin-top: 18%;
  }
  .home-app-area {
    min-height: 100vh;
  }
}

.containers {
  width: 30%;
  margin-left: 8%;
  position: relative;
  text-align: center;
  align-self: center;
  margin-top: 0;
}

/**
About Area
*/
.about-area__content h4 {
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #474747;
}

.about-area__content h3 {
  color: #303030;
  text-transform: uppercase;
  padding-right: 50px;
  margin-bottom: 25px;
}

.about-area__content a.cr-btn {
  margin-top: 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-area__image {
    padding: 50px 0;
    text-align: center;
    max-height: auto;
  }
  .about-area__image img {
    position: relative;
  }
  .about-area__content {
    padding: 0;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .about-area__image {
    padding: 50px 0;
    text-align: center;
    max-height: auto;
  }
  .about-area__image img {
    max-width: 100%;
    position: relative;
  }
  .about-area__image img {
    max-width: 100%;
  }
  .about-area__content {
    padding: 0;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .home-top-bg-1 {
    background-image: url(/static/media/EcoCash-Banner-mobile.5986b582.jpg);
  }

  .home-top-desc-green {
    color: white;
  }

  .home-top-bg-2 {
    background-position: 80% center;
  }
}

@media only screen and (max-width: 575px) {
  .about-area__content {
    padding-left: 0;
    padding-right: 0;
  }
  .about-area__content h3 {
    padding-right: 0;
    padding-left: 0;
  }
}

/* --------------------------------

Primary style

-------------------------------- */
.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}
.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}
.cd-words-wrapper b.is-visible {
  position: relative;
}
.no-js .cd-words-wrapper b {
  opacity: 0;
}
.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* --------------------------------

xslide

-------------------------------- */
.cd-headline.slide span {
  display: inline-block;
}
.cd-headline.slide .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
  width: auto !important;
}
.cd-headline.slide b {
  opacity: 0;
  top: 0.2em;
}
.cd-headline.slide b.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in 0.6s;
  animation: slide-in 0.6s;
}
.cd-headline.slide b.is-hidden {
  -webkit-animation: slide-out 0.6s;
  animation: slide-out 0.6s;
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.color--theme {
  color: rgba(59, 172, 240);
}

.home-app-area {
  min-height: 50vh;
  background-color: #154989;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-app-area-title-text {
  line-height: 1.5;
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 18px;
  font-family: BentonSans, "Rubik", "Oswald", sans-serif;
  margin-top: 20px;
}

.home-app-area-sub-title-text {
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 48px;
  text-align: center;
  font-family: BentonSans, "Rubik", "Lato", Arial, sans-serif;
}

.home-app-area-sub-title-area {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.home-app-area-sub-button {
  padding: 20px;
  padding-bottom: 20px;
  background-color: #154989;
  color: white;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
}

.transaparent-button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin: 0;
  padding: 0;
}

.transaparent-button.ant-btn:hover {
  background-color: transparent;
}

.send-money-white-button.ant-btn {
  background-color: transparent;
  box-sizing: border-box;
  color: #154989;
  margin-bottom: 8px;
  margin-right: 20px;
  font-size: 17px;
  padding: 11.5px 32px;
  height: auto;
  border-radius: 4px;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  border: 2px solid #154989;
}

.send-money-green-button.ant-btn {
  /* background-color: rgb(78, 140, 50); */
  background-color: #154989;
  /* background-color : rgb(48, 87, 30); */
  box-sizing: border-box;
  color: white;
  margin-bottom: 8px;
  margin-right: 20px;
  border: 1px solid white;
  font-size: 17px;
  padding: 11.5px 32px;
  height: auto;
  border-radius: 0;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  /* border: 1px solid rgb(78, 140, 50); */
  border: 1px solid #154989;
}

.learn-more-button.ant-btn {
  background-color: white;
  box-sizing: border-box;
  color: #154989;
  margin-bottom: 8px;
  border: 1px solid white;
  font-size: 17px;
  padding: 11.5px 32px;
  height: auto;
  border-radius: 4px;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  border: 1px solid white;
}

.addon-body {
  position: relative;
  width: 100%;
  min-height: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 28vh;
}

@media screen and (max-width: 768px) {
  .home-app-area-sub-title-text {
    font-size: 40px;
  }
  .home-app-area-title-text {
    letter-spacing: 0.3em;
  }
}

@media screen and (max-width: 500px) {
  .home-app-area-sub-title-text {
    font-size: 38px;
  }
}


.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1; }
    .owl-carousel .owl-stage {
      position: relative;
      -ms-touch-action: pan-Y; }
    .owl-carousel .owl-stage:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    .owl-carousel .owl-stage-outer {
      position: relative;
      overflow: hidden;
      /* fix for flashing background */
      -webkit-transform: translate3d(0px, 0px, 0px); }
    .owl-carousel .owl-item {
      position: relative;
      min-height: 1px;
      float: left;
      -webkit-backface-visibility: hidden;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none; }
    .owl-carousel .owl-item img {
      display: block;
      width: 100%;
      -webkit-transform-style: preserve-3d; }
    .owl-carousel .owl-nav.disabled,
    .owl-carousel .owl-dots.disabled {
      display: none; }
    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next,
    .owl-carousel .owl-dot {
      cursor: pointer;
      cursor: hand;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .owl-carousel.owl-loaded {
      display: block; }
    .owl-carousel.owl-loading {
      opacity: 0;
      display: block; }
    .owl-carousel.owl-hidden {
      opacity: 0; }
    .owl-carousel.owl-refresh .owl-item {
      visibility: hidden; }
    .owl-carousel.owl-drag .owl-item {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    .owl-carousel.owl-grab {
      cursor: move;
      cursor: -webkit-grab;
      cursor: grab; }
    .owl-carousel.owl-rtl {
      direction: rtl; }
    .owl-carousel.owl-rtl .owl-item {
      float: right; }
  
  /* No Js */
  .no-js .owl-carousel {
    display: block; }
  
  /*
   *  Owl Carousel - Animate Plugin
   */
  .owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
            animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }
  
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  
  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut; }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  
  @keyframes fadeOut {
    0% {
      opacity: 1; }
    100% {
      opacity: 0; } }
  
  /*
   * 	Owl Carousel - Auto Height Plugin
   */
  .owl-height {
    -webkit-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out; }
  
  /*
   * 	Owl Carousel - Lazy Load Plugin
   */
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    transition: opacity 400ms ease; }
  
  .owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; }
  
  /*
   * 	Owl Carousel - Video Plugin
   */
  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000; }
  
  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform 100ms ease;
    -webkit-transition: -webkit-transform 100ms ease;
    transition: transform 100ms ease;
    transition: transform 100ms ease, -webkit-transform 100ms ease; }
  
  .owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3); }
  
  .owl-carousel .owl-video-playing .owl-video-tn,
  .owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none; }
  
  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    transition: opacity 400ms ease; }
  
  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%; }

.dashboard-content-container {
  position: relative;
  margin-bottom: 10vh;
}

.stories-body {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.terms-body {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: auto;
  padding-left: 15%;
  padding-right: 15%;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  line-height: 1.6;
  color: #647072;
}

.get-help-body {
  position: relative;
  margin-top: 15vh;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 10%;
  padding-right: 10%;
}

.faq-content-container {
  position: relative;
  display: inline;
  width: 100%;
  padding-left: 13vw;
  padding-right: 13vw;
  margin-bottom: 10vh;
}

.faq-body {
  position: relative;
  margin-top: 50px;
}

.cookie-policy-body {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  margin-bottom: 50px;
  min-height: 100vh;
  padding-left: 15%;
  padding-right: 15%;
}

.dashboard-body {
  margin-top: 8%;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.media-body {
  margin-top: 5%;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.cash-points-body {
  margin-top: 8%;
  margin-bottom: 50px;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.agent-body {
  margin-top: 8% !important;
  margin-bottom: 8% !important;
  min-height: 500px;
  padding-left: 15%;
  padding-right: 15%;
}

.about-body {
}

.terms-ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.terms-ol > .terms-li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.terms-ol > .terms-li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

.terms-li terms-ol > .terms-li {
  margin: 0;
}

.terms-li terms-ol > .terms-li:before {
  content: counters(item, ".") " ";
}

.terms-list-body {
  position: relative;
  display: flex;
  flex-direction: column;
}

.partners-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.partners-logo {
  width: 18vw;
  object-fit: contain;
}

.about-mzansi {
  width: 50%;
  height: 80vh;
  margin-right: 5vh;
  background-repeat: no-repeat;
  background-position: 50%35%;
  background-image: url(/static/media/ecocacashza-1.a6fdb246.jpg) !important;
}

.about-mzansi-text {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.about-us-text {
  margin-top: 20px;
}

.misc-top {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.misc-top-title {
  padding: 6px;
  border: 4px solid #f4f4f433;
  font-size: 48px;
  text-align: center;
  color: white;
  font-family: BentonSans, 'Rubik', 'Oswald', sans-serif;
}

.misc-top-faqs {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/static/media/115135-Launch-Phase-SA-digitalv-35.3c63257b.jpg);
  background-position: 50% -98px;
  background-size: cover;
  background-repeat: no-repeat;
}

.misc-top-cash-out {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/static/media/blue-bg.a596d8f7.jpg);
  background-position: 50% -10px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-top-find-agent {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/static/media/EcoCash-Agent.4c6f96aa.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-top-title-white {
  padding: 6px;
  border: 4px solid #0c5ca9;
  font-size: 30px;
  text-align: center;
  color: white;
}

.misc-top-send-money {
  background-image: url(/static/media/send-money.59f5baed.jpg);
  background-position: 50% -10px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-top-gen {
  padding-top: 145px;
  padding-bottom: 145px;
  background-color: rgba(245, 248, 250, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/static/media/banner-zesa-e1537346816582.94830373.jpg);
  background-position: 50% -98px;
  background-size: cover;
  background-repeat: no-repeat;
}

.send-money-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.send-money-body > div {
  padding-left: 12vw;
  padding-right: 13vw;
}

.send-money-row-1 {
  background-color: white;
  width: 100%;
}

.send-money-row-1-child {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
}

.send-money-row-3-child {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.send-money-row-3-child-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  padding-top: 20px;
  padding-bottom: 20px;
  background: white;
}

.send-money-row-3-child-body-desc {
  width: 15vw;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.send-money-row-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f8fa;
  width: 100%;
}

.send-money-row-1-child-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-right: 3vh;
  padding-left: 3vh;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
}

.send-money-row-1-child-body:first-child {
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(2) {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(3) {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(4) {
  border-left: 0;
  border-bottom: 0;
}
.send-money-row-1-child-body:nth-child(5) {
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
}

.send-money-row-1-child-body:nth-child(6) {
  border-right: 0;
  border-bottom: 0;
}

.send-money-row-1-child-title {
  font-size: 18px;
  color: black;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.send-money-row-1-child-body-desc {
  width: 20vw;
  text-align: center;
  color: #647072;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 400;
}

.send-money-body-title {
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 1.5;
  font-size: 48px;
  text-align: center;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: #242d2e;
}

.send-money-row-2-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.send-money-app {
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.send-money-app-title {
  font-size: 20px;
  color: black;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.send-money-app-desc {
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-size: 16px;
}

.get-quote-img {
  height: 50vh;
}

/* breadwinner home page */

.bwl-main-banner {
  width: 100%;
  height: 100vh;
  background: #000 url(/static/media/banner-image.415b9eba.jpg) no-repeat center;
  background-size: cover;
}

.rh-main-banner {
  width: 100%;
  height: 100vh;
  background: #000 url(/static/media/remit-hope-banner.3a731d4e.jpg) no-repeat center;
  background-size: cover;
}

.bwl-aside-gradient {
  color: white;
  width: 50%; 
  height: 100vh;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(80%, rgba(0, 0, 0, .8)), color-stop(90%, rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
}

.rh-aside-gradient {
  color: white;
  width: 50%; 
  height: 100vh;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(80%, rgba(0, 0, 0, .8)), color-stop(90%, rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
}

.bwl-main-banner-container {
  margin: 0 auto;
}

.bwl-main-banner-sub-container {
  width: 80%;
  margin: 0 auto;
  padding: 15% 20% 0 0;
}

.bwl-breadwinner-policy-logo {
  margin: 0 auto;
  height: 220px;
  width: auto;
}

.rh-remit-hope-logo {
  margin: 0 auto;
  height: 220px;
  width: auto;
  object-fit: cover;
}

.bwl-main-banner-text {
  color: white;
  font-size: 28px;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rh-main-banner-text {
  color: white;
  font-size: 28px;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rh-sub-banner-text {
  color: white;
  font-size: 20px;
  text-align: center;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.bwl-how-it-works-section {
  background: #fff url(/static/media/bwl-how-it-works-curve.f246a311.svg) no-repeat center;
  background-size: cover;
  color: white;
  box-sizing: border-box;
  height: auto;
  padding-bottom: 100px;
}

.bwl-how-it-works-heading {
  font-size: 70px;
  padding: 30px 0;
  color: white;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  text-align: center;
}

.remit-hope-category-heading {
  font-size: 50px;
  padding: 0 0;
  margin: 0 0;
  margin-top: 32px;
  color: #104075;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  text-align: center;
}

.bwl-how-it-works-text {
  font-size: 30px;
  line-height: 35px;
  padding: 0 80px;
  margin: 0 auto;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  text-align: center;
}

.bwl-how-it-works-text-second-p {
  padding: 60px 80px;
  margin: 0 0 150px 0;
  text-align: left;
}

.bwl-htgs-curved-bg {
  background-image: url(/static/media/bwl-curved-bg.30051a94.svg);
  background-position: center;
  background-size: cover;
  margin: 0 0 30px 0;
}

.bwl-htgs-heading {
  font-size: 70px;
  padding: 30px 0;
  color: #104075;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.bwl-htgs-first-level-bubbles {
  height: 150px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 50px rgba(7, 42, 68, .23);
  margin: 80px 0;
}

.bwl-htgs-bubble-padding {
  padding: 20px;
}

.bwl-htgs-bubble-icon-size {
  width: 40px;
  height: 40px;
}

.bwl-htgs-bubble-heading-margin {
  margin: 10px 0;
}

.bwl-htgs-bubble-text {
  font-size: 14px;
}

.bwl-hgts-second-level-bubbles {
  height: 150px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 50px rgba(7, 42, 68, .23);
  margin: 100px 0;
}

.bwl-htgs-center-mobile-phone {
  width: 252px;
  height: 600px;
  background-image: url(/static/media/bwl-mobile.1aab2c08.png);
  background-size: cover;
  background-position: 50% 50%;
}

.bwl-play-app-store-badge-row {
  margin: 0 0 150px 0;
}

.bwl-play-app-store-badge-size {
  height: 65px;
  width: 260px;
}

.pointer {
  cursor: pointer;
}

.bwl-benefits-heading {
  border: #104075 solid 2px;
  border-radius: 50px;
  font-size: 60px;
  padding: 0px 50px;
  display: inline;
  margin: 20px 0;
  color: #104075;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  text-align: center;
}

.bwl-benefits-text {
  color: #104075;
  font-size: 30px;
  line-height: 35px;
  padding: 0 60px 50px;
  margin: 0 auto;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  text-align: center;
}

.bwl-benefits-list {
  color: #104075;
  font-size: 30px;
  line-height: 35px;
  padding: 0 60px 25px;
  margin: 0 auto;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  text-align: left;
}

.bwl-address {
  list-style-type: none;
}

.bwl-justify-self-center {
  display: flex;
  justify-self: center;
}

.rh-justify-self-center {
  width: 100%;
  display: flex;
  justify-self: center;
  justify-content: center;
}

.bwl-justify-content-center {
  display: flex;
  justify-content: center;
}

.bwl-read-more-btn, .bwl-read-more-btn:hover {
  padding: 10px 30px;
  border-radius: 20px;
  background-color: #104075;
  margin: 20px 0;
  color: white;
  font-weight: regular;
  font-size: 1.3em;
  text-decoration: none;
}

.rh-learn-more-btn, 
.rh-learn-more-btn:hover, 
.rh-learn-more-btn:focus, 
.rh-learn-more-btn:focus-visible {
  border: none;
  padding: 10px 30px;
  border-radius: 20px;
  background-color: #104075;
  margin: 20px 0;
  color: white;
  font-weight: regular;
  font-size: 1.3em;
  text-decoration: none;
  outline: none;
}

.bwl-benefits-grey-bg-padding {
  background-color: #fafafa;
  padding: 20px 0;
}

.bwl-benefits-padding {
  padding: 20px 0;
}

.bwl-remove-margin {
  margin: 0 !important;
}

.bwl-disclaimer {
  font-size: 10px;
  line-height: 15px;
  text-align: center;
}
/* end of breadwinner landing page styling */

@media screen and (max-width: 768px) {
  .about-mzansi {
    display: none;
  }
  .partners-logo {
    width: 20vw;
    object-fit: contain;
  }
  .dashboard-body {
    margin-top: 10%;
  }
  .media-body {
    margin-top: 10%;
  }

  .bwl-main-banner-sub-container {
    padding: 0 10px;
  }

  .bwl-how-it-works-text {
    font-size: 35px;
    line-height: 40px;
    padding: 0 20px;
  }

  .bwl-benefits-text, .bwl-benefits-list {
    font-size: 35px;
    line-height: 40px;
    padding: 0 20px;
  }

  .bwl-benefits-list {
    font-size: 35px;
    line-height: 40px;
    padding: 0 20px;
  }

  .bwl-main-banner-text {
    font-size: 20px;
  }

  .bwl-address {
    list-style-type: none;
    padding-left: 20px;
  }

}

.about-top-bg {
  background-image: url(/static/media/about-us.bb3a10a3.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.hiw-top-bg {
  background-image: url(/static/media/how-it-works.da09189e.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.terms-top-bg {
  background-image: url(/static/media/terms-conditions.4d6c68e7.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.faqs-top-bg {
  background-image: url(/static/media/115135-Launch-Phase-SA-digitalv-35.3c63257b.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.help-top-bg {
  background-image: url(/static/media/contact-us.2d2ddbb7.jpg);
  background-position: 50% -75px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.agent-top-bg {
  background-image: url(/static/media/EcoCash-Agent.4c6f96aa.jpg);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
}

.misc-page-overlay {
  background-color: #46c2cf8c;
  padding-top: 145px;
  padding-bottom: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hiw-app-text {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 8px;
}

.hiw-app-text-title {
  font-size: 18px;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: #242d2e;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.hiw-app-text-desc {
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  color: #647072;
  font-weight: 400;
  line-height: 1.75;
}

.hiw-app-area-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 40%;
  justify-content: center;
}

.hiw-app-area-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.hiw-app-reg-desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 860px) {
  .send-money-row-1-child-body-desc {
    width: 100%;
  }

  .send-money-row-1-child > div {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
  }

  .send-money-app {
    width: 100%;
    margin-bottom: 60px;
  }

  .send-money-row-3-child-body-desc {
    width: 100%;
  }

  .send-money-row-1-child-body {
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-right: 3vh;
    padding-left: 3vh;
  }

  .hiw-app-area-column {
    width: 80%;
    align-items: center;
  }

  .hiw-app-area-desc {
    justify-content: center;
  }

  .cash-points-body {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 1050px) {
  .misc-top-faqs {
    background-size: auto;
    background-position: 0;
  }
  .misc-top-gen {
    background-size: auto;
    background-position: 0;
  }
  .about-mzansi-text {
    width: 80%;
  }
}

.cashout-content-container {
  position: relative;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.cashout-table-body {
  position: relative;
  width: 100%;
  min-height: 0;
  height: auto;
  margin-top: 30px;
  padding-left: 0px;
  padding-right: 0px;
}

.panel-child {
  color: #777;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5em;
  padding: 16px;
}

.terms-item-parent {
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 900;
  /* font-family: "Lato Black", Lato, sans-serif; */
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  color: black;
}

.terms-item-child {
  margin-top: 8px;
  margin-bottom: 8px;
}

.terms-item-child-bold {
  font-weight: 900;
  /* font-family: "Lato Black", Lato, sans-serif; */
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  color: black;
  margin-top: 8px;
  margin-bottom: 8px;
}

.terms-item-bold {
  font-weight: 900;
  /* font-family: "Lato Black", Lato, sans-serif; */
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: black;
}

.stories-title {
  margin-bottom: 45px;
  text-align: center;
  color: black;
  font-weight: 700;
}

.stories-image {
  object-fit: contain;
  margin-bottom: 45px;
  min-width: 10vw;
}

@media screen and (max-width: 993px) {
  .bwl-htgs-center-mobile-phone{
    display: none !important;
  }

  .bwl-htgs-bubble-container{
    flex: 0 0 50% !important;
    max-width: 500px;
  }

  .bwl-main-banner-text {
    font-size: 30px;
  }

  .bwl-how-it-works-heading {
    font-size: 60px;
    padding: 15px 0;
  }

  .bwl-benefits-heading {
    font-size: 50px;
  }

  .bwl-how-it-works-section {
    padding-bottom: 180px;
  }

  .bwl-how-it-works-text, .bwl-benefits-list {
    margin: 25px auto;
    text-align: left;
    font-size: 25px;
    line-height: 30px;
  }

  .bwl-benefits-text {
    margin: 25px auto;
    text-align: center;
    font-size: 25px;
    line-height: 30px;
  }

  .bwl-how-it-works-text-second-p {
    padding: 20px 80px;
  }

  .bwl-htgs-heading {
    font-size: 60px;
  }

  .bwl-htgs-first-level-bubbles {
    margin: 40px 0;
  }

  .bwl-aside-gradient {
    color: white;
    width: 70vw; 
    height: 100vh;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(80%, rgba(0, 0, 0, .8)), color-stop(90%, rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
  }

  .rh-aside-gradient {
    color: white;
    width: 70vw; 
    height: 100vh;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(80%, rgba(0, 0, 0, .8)), color-stop(90%, rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .8) 80%, rgba(0, 0, 0, .4) 90%, rgba(0, 0, 0, 0));
  }
  
}

@media screen and (max-width: 860px) {
  .stories-image {
    object-fit: cover;
  }
  .agent-page-text {
    font-size: 8px;
  }

  .bwl-main-banner-text {
    font-size: 35px;
  }
}

@media screen and (max-width: 768px) {
  .agent-table-area {
    width: 100% !important;
  }
  .agent-body {
    padding-left: 10%;
    padding-right: 10%;
  }

  .cookie-policy-body {
    margin-top: 30vh;
  }

  .bwl-aside-gradient {
    color: white;
    width: 100vw; 
    height: 100vh;
    background-image:  -webkit-gradient(linear, left top, right top, color-stop(80%, rgba(0, 0, 0, .6)), to(rgba(0, 0, 0, .6)));
    background-image:  -webkit-linear-gradient(left, rgba(0, 0, 0, .6) 80%, rgba(0, 0, 0, .6));
    background-image:  linear-gradient(to right, rgba(0, 0, 0, .6) 80%, rgba(0, 0, 0, .6));
  }

  .rh-aside-gradient {
    color: white;
    width: 100vw; 
    height: 100vh;
    background-image:  -webkit-gradient(linear, left top, right top, color-stop(80%, rgba(0, 0, 0, .6)), to(rgba(0, 0, 0, .6)));
    background-image:  -webkit-linear-gradient(left, rgba(0, 0, 0, .6) 80%, rgba(0, 0, 0, .6));
    background-image:  linear-gradient(to right, rgba(0, 0, 0, .6) 80%, rgba(0, 0, 0, .6));
  }

  .bwl-benefits-heading {
    font-size: 40px;
    padding: 0;
    border: none;
  }

  .bwl-how-it-works-text, .bwl-benefits-list {
    font-size: 24px;
    line-height: 30px;
    padding: 0 25px;
  }

  .bwl-benefits-text {
    font-size: 24px;
    line-height: 30px;
    padding: 0 25px;
  }

  .bwl-htgs-heading {
    padding: 0;
    margin: 0;
  }

  .bwl-htgs-bubble-padding {
    padding: 15px;
  }

  .bwl-htgs-bubble-heading-margin {
    margin: 5px 0;
  }

  .bwl-hgts-second-level-bubbles {
    margin: 50px 0;
  }
}

@media screen and (max-width: 900px) {
  .cash-point-table .ant-table-thead > tr > th {
    font-size: 13px !important;
  }

  .cash-point-table .ant-table-tbody > tr > td {
    font-size: 13px !important;
  }

  .promotions-image {
    width: 60% !important;
    height: 50vh;
  }
}

@media screen and (max-width: 768px) {
  .cash-point-table .ant-table-thead > tr > th {
    font-size: 10px !important;
  }

  .cash-point-table .ant-table-tbody > tr > td {
    font-size: 10px !important;
  }
  .promotions-image {
    width: 70% !important;
    height: 50vh;
  }
}

@media screen and (max-width: 500px) {
  .cash-point-table .ant-table-thead > tr > th {
    font-size: 9px !important;
  }

  .cash-point-table .ant-table-tbody > tr > td {
    font-size: 9px !important;
  }
  .promotions-image {
    width: 80% !important;
    height: 50vh !important;
  }

  .dashboard-body {
    margin-top: 20%;
  }

  .media-body {
    margin-top: 20%;
  }

  .bwl-main-banner-text {
    font-size: 28px;
  }

  .bwl-benefits-heading {
    font-size: 30px;
  }

  .bwl-how-it-works-text, .bwl-benefits-list {
    font-size: 20px;
    line-height: 27px;
    padding: 0 10px;
  }

  .bwl-benefits-text {
    font-size: 20px;
    line-height: 27px;
    padding: 0 10px;
  }

  .bwl-htgs-steps-container {
    flex-direction: column;
  }

  .bwl-play-app-store-badge-size {
    height: 50px;
    width: auto;
    margin: 0 20px;
  }

  .bwl-play-app-store-badge-row {
    margin: 0 0 50px 0;
    padding: 0 0 50px 0;
  }

  .bwl-address {
    font-size: 20px;
    line-height: 30px;
  }
}

.agent-table .ant-table-thead > tr > th {
  padding: 7px 10px;
  line-height: 16px;
  background: #154989;
  color: white;
  text-align: center;
  font-size: 11px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 400;
}

.agent-table .ant-table-tbody > tr > td {
  padding: 3px 10px;
  font-size: 11px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-style: normal;
  color: #666666;
  font-weight: 400;
}

.agent-table-area {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.agent-page-text {
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .cash-out-agents {
    min-height: 400px !important;
  }

  .breadwinner-policy-container {
    width: 90% !important;
  }
}

.cash-point-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.cash-point-table .ant-table-thead > tr > th {
  padding: 7px 10px;
  line-height: 16px;
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 700;
  padding: 8px;
}

.cash-point-table .ant-table-tbody > tr > td {
  padding: 3px 10px;
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-style: normal;
  color: #666666;
  font-weight: 400;
}

.cash-out-agents {
  width: 100%;
  height: auto;
  min-height: 800px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/static/media/cash_out_agents.01a614c3.png) !important;
  margin-top: 0px;
  background-position: center;
}

ul.cross {
  margin: 0;
  list-style-type: none;
}
ul.cross > li {
  text-indent: 5px;
}

ul.cross > li:before {
  content: "x";
  font-size: 10px;
  text-indent: 5px;
  padding-right: 8px;
}

ul.tick {
  margin: 0;
  list-style-type: none;
}
ul.tick > li {
  text-indent: 5px;
}

ul.tick > li:before {
  content: "\2714\0020";
  font-size: 10px;
  text-indent: 5px;
  padding-right: 8px;
}

ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
ul.dash > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  /* margin-left: -1em; */
}

.bread-winner-h4 {
  background: #154989;
  color: white;
}

.bread-winner-h6 {
  color: #154989;
}

.breadwinner-logo {
  height: 20vh;
  min-height: 150px;
  width: auto;
  object-fit: contain;
}

.hollard-logo {
  height: 5vh;
  min-height: 50px;
  width: auto;
  object-fit: contain;
}

@media only screen and (max-width: 2000px) {
  .breadwinner-content-container {
    position: relative;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .bwl-how-it-works-section {
    /* height: 10vh; */
  }

  .bwl-how-it-works-heading {
    font-size: 50px;
  }

  .bwl-benefits-heading {
    font-size: 40px;
    padding: 0;
    border: none;
  }

  .bwl-htgs-heading {
    font-size: 50px;
  }

  .bwl-play-app-store-badge-size {
    height: 40px;
    width: auto;
    margin: 0 5px;
  }
  
  .breadwinner-content-container {
    position: relative;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 
  
  .breadwinner-policy-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }
  
  .breadwinner-policy-child-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .bwl-address {
    font-size: 15px;
    line-height: 20px;
    padding-left: 10px;
  }
}


.quote-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border-radius: 4px;
  padding: 40px;
  width: 70%;
  margin-top: 10%;
  margin-bottom: 10vh;
}

.quote-body--form {
  position: relative;
  text-align: center;
  margin-top: 10px;
  width: 80%;
  margin-left: 10%
}

.quote-layout {
  position: relative;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgb(41, 108, 187)), to(#154989));
  background: -webkit-linear-gradient(left, rgb(41, 108, 187), #154989);
  background: linear-gradient(to right, rgb(41, 108, 187), #154989);
}

.quote-box1 {
  flex: 1 1;
}

.quote-box2 {
  position: relative;
  background: #f2f2f2;
  padding: 40px 240px;
  text-align: left;
  flex: 2 1;
}

.quote-box3 {
  flex: 1 1;
}

.quote-open-account {
   text-align: center;
   margin-bottom: 40px;
   font-size: 20px;
}

.quote-upper-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.quote-mid-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.quote-left-title-text {
   width: 100%;
   text-align: center;
   margin-top: 0px;
   font-size: 24px;
}

.quote-address-text {
   text-align: center;
   width: 100%;
   margin: 20px;
   font-size: 20px;
}

.quote-body {
   margin: auto;
   width: 100%;
   height: 100%;
   padding-left: 18%;
   padding-right: 18%;
   padding-top: 5%;
   padding-bottom: 5%;
}

.quote-body-2 {
   position: relative;
   width: 70%;
   display: flex;
   flex-direction: column;
   background-color: whitesmoke;
   margin-top: 60px;
   margin-bottom: 60px;
}

.quote-image-container {
   position: relative;
   width: auto;
   max-width:20%;
   height: 120vh;
   right: 0;
}

.quote-item-2 {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.quote-item-upload-text {
  margin: 30px;
  font-size: 20px;
  text-align: center;
}

.quote-item-upload {
   width: 100%;
   position: relative;
}

.quote-btn-sign-up {
   width: 100%;
}

.quote-tf {
   width: 40%;
   margin-top: 10px;
   margin-right: 10px;
}

.quote-fieldset {
  border-bottom: 1px solid #dedede;
  padding-top: 10px;
  padding-bottom: 40px;
  margin-bottom: 10px;
}

.quote-tf-2 {
   width: 100%;
   margin-top: 15px;
   margin-bottom: 15px;
   margin-right: 10px;
}

.ant-calendar-picker-input {
    height: 46px;
    border-radius: 0px;
}

.ant-dropdown-trigger, .dropdown-item {
  height: 30px;
  background: #fff;
  border: 1px solid #ced4da
}

.quote-upload > .ant-upload.ant-upload-select-picture-card {
   margin-top: 10px;
   width: 100%;
}

.quote-image {
   width: 50%;
}

.quote-smile-guy {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.quote-custom-row.ant-row.ant-form-item {
   width: 48%;
   margin-right:0px;
}

.quote-custom-row-full.ant-row.ant-form-item {
   width: 100%;
}

.quote-autocomplete-row-full {
  width: 50%;
}

.quote-row-full {
  width: 100%;
}


.quote-custom-btn-create-account.ant-btn.ant-btn-primary {
   width: 100%;
}

.quote-address-item {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
}

.quote-bread-winner-title-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.quote-bread-winner-text-title {
  font-size: 20px;
}

.quote-bread-winner-note-title {
  font-size: 11px;
  color: red;
}

@media screen and (max-width: 1024px) and (min-width: 768px)  {
   .quote-custom-btn-create-account-row { width: 50%; }
}

@media screen and (max-width: 1024px) {
   .quote-body { padding-left: 5%; padding-right: 5%; }
}

@media screen and (max-width: 800px) {
  .quote-body-2 { width: 100%; padding-left: 5%; padding-right: 5%; }

}

@media screen and (max-width: 860px) {
  .quote-content-container { padding: 20px; width: 100%; }
  .quote-title {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 1100px) {
   .quote-content-container { margin-top: 30%; }
}

@media screen and (max-width: 600px) {
   .quote-content-container { margin-top: 40%; width: 100% }
}

@media screen and (max-width: 768px) {
   .quote-custom-row.ant-row.ant-form-item{ width: 100%; }
   .quote-image-container{ display: none; }
}

.pg-appintment__title {
  color: #00AC1F; }

.pg-appintment__box {
  margin-top: -30px; }

.quote-row {
  width: 100%;
  display: flex;
  padding: 20px 20px;
  margin: 0px auto;
}

.quote-column1 {

}

.quote-column2 {

}

.quote-column3 {

}

.form-control {
  height: 46px;
  border-radius: 0px;
}

.quote-btn-register {
  margin-right: 10px;
  background-color: #154989;
  border: 1px solid #154989;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  height: 46px;
}

.ant-select-selection {
  height: 46px;
  border-radius: 0px;
}
.ant-select-selection__rendered {
  padding-top: 8px;
  vertical-align: middle;
}

.quote-rate-result-parent {
  width: 100%;
  display: flex;
  flex-direction:column;
  margin-top: 2px;
  margin-bottom: 20px;
}

.qoute-rate-final {
  background: rgba(255,255,255,0.5);
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.quote-amount-parent-box {
  display: flex;
  width: 100%;
}
.quote-amount-left-column {
  flex: 1 1;
  display: flex;
  flex-direction:row;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 2%;
}

.remit-hope-donation-amount {
  /* flex: 1; */
  display: flex;
  flex-direction:row;
  margin-top: 8px;
  /* margin-bottom: 8px; */
  margin-right: 2%;
}

.quote-amount-right-column {
  flex: 1 1;
  display: flex;
  flex-direction:row;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 2%;
}

.ant-form-item-label {
  font-size: 16px;
  font-weight: 600;
  color: #3e3e3e;

}

.quote-lines {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  font-size: 24px;
  font-weight: 400;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.quote-lines:before {
  margin-left: -100%;
}
.quote-lines:after {
  margin-right: -100%;
}
.quote-lines:before, .quote-lines:after {
  display: inline-block;
  content: '';
  background-color: #bfbfbf;
  height: 1px;
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  border: solid #FFF;
  border-width: 0 10px;
}


.quote-form--1 {
  width: 100%;
  margin-right: 1%;
}

.quote-form-2 {
  width: 48%;
  margin-left: 1%;

}

.quote-title {
  margin-top: 0px;
  color: white;
  width: 100%;
  text-align: center;
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.quote-product-info-text {
  font-weight: 400;
  /* font-family: 'Lato-Bold'; */
  font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
  color: red;
}

@media screen and (max-width: 479px) {
  .quote-form-1 {
      width: 100%;
      margin: 0px;
  }

  .quote-form-2 {
      width: 100%;
      margin: 0px;
  }
}

a {
  text-decoration: none;
}

a :hover {
  text-decoration: none;
}

.log-container {
   position: relative;
   width: 100%;
   height: 100%;
   margin: 0;
   padding: 0;
}

.log-layout {
   position: relative;
   width: 100%;
   background: -webkit-gradient(linear, left top, left bottom, from(rgb(41, 108, 187)), to(#154989));
   background: -webkit-linear-gradient(top, rgb(41, 108, 187), #154989);
   background: linear-gradient(to bottom, rgb(41, 108, 187), #154989);
}

.login-form-forgot {
    text-align: right;
    margin-bottom: 20px;
    flex: 1 1;
}

.log-line {
   line-height: 1.6;
   font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.log-sub-line {
   line-height: 1.8;
}

.log-rmb-forgot-container{
  display: flex;
}

.login-content-container {
   position: relative;
   margin-left: auto;
   margin-right: auto;
   display: flex;
   flex: none;
   flex-direction: column;
   align-items: center;
   background: #f2f2f2;
   border-radius: 8px;
   padding: 20px;
   min-width: 30%;
   margin-top: 20vh;
   margin-bottom: 10vh;
}
.log-body {
  position: relative;
  text-align: center;
  margin-top: 60px;
  width: 70%;
}

@media screen and (max-width: 991px) {
  .login-content-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    background: #f2f2f2;
    border-radius: 8px;
    padding: 20px;
    min-width: 30%;
    margin-top: 20vh;
    margin-bottom: 10vh;
 }
  .log-body {
    position: relative;
    text-align: center;
    margin-top: 60px;
    width: 80%;
  }
  }

  @media screen and (max-width: 767px) {
    .login-content-container {
      position: relative;
      margin-left: 5%;
      margin-right: 5%;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      background: #f2f2f2;
      border-radius: 8px;
      padding: 20px;
      min-width: 30%;
      margin-top: 20vh;
      margin-bottom: 10vh;
   }
  .log-body {
    position: relative;
    text-align: center;
    margin-top: 60px;
    width: 90%;
  }
  }

  @media screen and (max-width: 479px) {
    .login-content-container {
      position: relative;
      margin-left: 5%;
      margin-right: 5%;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      background: #f2f2f2;
      border-radius: 8px;
      padding: 20px;
      width: 90%;
      margin-top: 30vh;
      margin-bottom: 10vh;
   }
  .log-body {
    position: relative;
    text-align: center;
    margin-top: 60px;
    width: 95%;
  }
  }


.log-remember {
  text-align: left;
   margin-bottom: 20px;
   flex: 1 1;
}

.log-btn-calculate {
   margin-right: 10px;
   background-color: #57a6d0;
   border: 1px solid #57a6d0;
   width: 100%;
   border-radius: 4px;
   padding: 10px;
   height: 40px;
}

.log-btn-calculate :hover{
  margin-right: 10px;
  background-color: #57a6d0;
  border: 1px solid #57a6d0;
  width: 200px;
  border-radius: 4px;
  padding: 10px;
  height: 40px;
}

.log-custom-footer.ant-layout-footer {
   padding: 0;
   width: 100%;
   bottom: 0;
   position: static;
}

.log-button2 {
   margin-right: 25px;
   background-color: Transparent;
   margin-bottom: 20px;
   border: 1px solid #57a6d0;
   width: 100%;
   border-radius: 4px;
   padding: 10px;
   height: 40px;
}

.signup-button {
  margin-right: 25px;
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid #57a6d0;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  height: 40px;
}

@media screen and (min-width: 320px) {
   .log-container { height: 100vh; }
}

@media screen and (min-height: 500px) {

   .log-body {
      margin: 5%;
   }

   .log-custom-footer.ant-layout-footer {

   }
}

@media screen and (max-width: 768px) {
   .log-content-container {
     max-width: 300px;
   }
}

@media screen and (max-width: 320px), screen and (max-height: 320px) {
   .log-custom-footer.ant-layout-footer {
      position: static;
   }
}
.or {
    width: 100%;
    height: 51px;
    text-align: center;
    font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: normal;
    line-height: 31px;
    margin-top: 50px;
    border-top: 1px solid #616161;
}

.lines {
  width: 100%;
  height: 51px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: Helvetica, 'Didact Gothic', 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.lines:before {
  margin-left: -100%;
}
.lines:after {
  margin-right: -100%;
}
.lines:before, .lines:after {
  display: inline-block;
  content: '';
  background-color: #bfbfbf;
  height: 1px;
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  border: solid #FFF;
  border-width: 0 10px;
}

.su-content-container {
   position: relative;
   display: flex;
   flex-direction:row;
   width: 100%;
   align-items:center;
   justify-content: center;
}

.signup-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border-radius: 0px;
  padding: 20px;
  width: 60%;
  margin-top: 15vh;
  margin-bottom: 10vh;
}

.log-body2 {
  position: relative;
  text-align: center;
  margin-top: 60px;
  width: 60%;
}

.signup-body {
  position: relative;
  text-align: center;
  margin-top: 10px;
  width: 80%;
}


@media screen and (max-width: 991px) {
  /* start of large tablet styles */
  .signup-content-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    background: #f2f2f2;
    border-radius: 0px;
    padding: 15px;
    width: 70%;
    margin-top: 15vh;
    margin-bottom: 10vh;
  }

  .signup-body {
    position: relative;
    text-align: center;
    margin-top: 10px;
    width: 90%;
  }
  }

  @media screen and (max-width: 767px) {
    .signup-content-container {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      background: #f2f2f2;
      border-radius: 0px;
      padding: 10px;
      width: 80%;
      margin-top: 15vh;
      margin-bottom: 10vh;
    }
  .signup-body {
    position: relative;
    text-align: center;
    margin-top: 10px;
    width: 95%;
  }
  }

  @media screen and (max-width: 479px) {
    .signup-content-container {
      position: relative;
      margin-left: 2%;
      margin-right: 2%;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      background: #f2f2f2;
      border-radius: 0px;
      padding: 10px;
      width: 95%;
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
    .signup-body {
      position: relative;
      text-align: center;
      margin-top: 10px;
      width: 95%;
    }
  }


.su-layout {
  position: relative;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(41, 108, 187)), to(#154989));
  background: -webkit-linear-gradient(top, rgb(41, 108, 187), #154989);
  background: linear-gradient(to bottom, rgb(41, 108, 187), #154989);
}

.su-box1 {
  flex: 1 1;
}

.su-box2 {
  position: relative;
  background: #f2f2f2;
  padding: 40px 240px;
  text-align: left;
  flex: 2 1;
}

.su-box3 {
  flex: 1 1;
}

.su-open-account {
   text-align: center;
   margin-bottom: 40px;
   font-size: 20px;
}

.su-upper-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.su-mid-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.su-left-title-text {
   width: 100%;
   text-align: left;
   margin-top: 20px;
   margin-bottom: 10px;
   font-size: 20px;
}

.su-address-text {
   text-align: center;
   width: 100%;
   margin: 20px;
   font-size: 20px;
}

.su-body {
   margin: auto;
   width: 100%;
   height: 100%;
   padding-left: 18%;
   padding-right: 18%;
   padding-top: 5%;
   padding-bottom: 5%;
}

.su-body-2 {
   position: relative;
   width: 70%;
   display: flex;
   flex-direction: column;
   background-color: whitesmoke;
   margin-top: 60px;
   margin-bottom: 60px;
}

.su-image-container {
   position: relative;
   width: auto;
   max-width:20%;
   height: 120vh;
   right: 0;
}

.su-item-2 {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.su-item-upload-text {
  margin: 30px;
  font-size: 20px;
  text-align: center;
}

.su-item-upload {
   width: 100%;
   position: relative;
}

.su-btn-sign-up {
   width: 100%;
}

.su-tf {
   width: 40%;
   margin-top: 10px;
   margin-right: 10px;
}

.su-fieldset {
  border-bottom: 1px solid #dedede;
  padding-top: 20px;
  padding-bottom: 40px;
}

.su-tf-2 {
   width: 100%;
   margin-top: 15px;
   margin-bottom: 15px;
   margin-right: 10px;
}

.ant-calendar-picker-input {
    height: 46px;
    border-radius: 0px;
}

.ant-dropdown-trigger, .dropdown-item {
  height: 40px;
  background: #fff;
  border: 1px solid #ced4da
}

.su-upload > .ant-upload.ant-upload-select-picture-card {
   margin-top: 10px;
   width: 100%;
}

.su-image {
   width: 50%;
}

.su-smile-guy {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.su-custom-row.ant-row.ant-form-item {
   width: 48%;
   margin-right:0px;
}

.su-custom-row-full.ant-row.ant-form-item {
   width: 100%;
}

.su-autocomplete-row-full {
  width: 50%;
}

.su-row-full {
  width: 100%;
}

.su-custom-btn-create-account.ant-btn.ant-btn-primary {
   width: 100%;
}

.su-address-item {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
}

.su-bread-winner-title-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.su-bread-winner-text-title {
  font-size: 20px;
}

.su-bread-winner-note-title {
  font-size: 11px;
  color: red;
}

@media screen and (max-width: 1024px) and (min-width: 768px)  {
   .su-custom-btn-create-account-row { width: 50%; }
}

@media screen and (max-width: 1024px) {
   .su-body { padding-left: 5%; padding-right: 5%; }
}

@media screen and (max-width: 800px) {
  .su-body-2 { width: 100%; padding-left: 5%; padding-right: 5%; }
}

@media screen and (max-width: 768px) {
   .su-custom-row.ant-row.ant-form-item{ width: 100%; }
   .su-image-container{ display: none; }

}

.pg-appintment__title {
  color: #00AC1F; }

.pg-appintment__box {
  margin-top: -30px; }

.su-row {
  width: 100%;
  display: flex;
  padding: 20px 20px;
  margin: 0px auto;
}

.su-column1 {

}

.su-column2 {

}

.su-column3 {

}

.form-control {
  height: 46px;
  border-radius: 0px;
}

.su-btn-register {
  margin-right: 10px;
  background-color: #57a6d0;
  border: 1px solid #57a6d0;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  height: 46px;
}

.ar-add-recipient {
	position: relative;
	margin-top: 30px;
	width: 100%;
	text-align: center;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rl-layout {
	position: relative;
	width:100%;
	min-width: 1000px;
	min-height: 800px;
	height: auto;
	margin-top: 10vh;
}

.ar-content-container {
	position: relative;
	width: 100%;
	padding-left: 20%;
	padding-right: 20%;
}

.ar-body {
	position: relative;
	margin-top: 10px;
}

.rl-container {
	position: relative;
	width: 100%;
	margin: 0;
	min-width: 800px;
}

.rl-content-container {
	position: relative;
	width: 100%;
	min-width: 800px;
	min-height: 500px;
	margin: 0;
	padding: 0;
}

.rl-select-recipient {
	position: relative;
	text-align: left;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rl-body {
	position: relative;
	width: 75%;
	min-height: 500px;
	height: 100%;
	margin-top: 30px;
	margin-left: 13vw;
}

.rl-top {
   position: relative;
   display: flex;
   justify-content:space-between;
   width: 100%;
   margin-top: 5%;
	 flex-wrap: wrap;
}

.rl-p-add-recipient {
	margin-left: 8px;
	background: #0b5ca9;
	color: #fff;
}

.rl-p-search-add-right {
	position: relative;
	text-align: right;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.rl-first-last-body {
   position: relative;
   display: flex;
   flex-direction:row;
   align-items:center;
   justify-content:space-around;
   width: 30%;
}

.pagination-box {
	position: relative;
	display: flex;
	margin-top: 2vh;
	width: 100%;
	margin-bottom: 10px;
	justify-content: space-around;

}

.pagination-box-right {
	flex: 1 1;
}

.pagination-box-middle {
	flex: 4 1;
	text-align: center;
	margin-left: 8px;
	margin-right: 8px;
}

.pagination-box-left {
	flex: 1 1;
}

.pagination-box-item {
	margin-left: 0px;
	margin-right: 5px;
	height: 30px;
	min-width: 100px;
	font-size: 16px;
	color: #154989;
	border: 1px solid #154989;
	border-radius: 0px;

}

.rl-prev-next-body {
   position: relative;
   width: 92%;
   margin-top: 30px;
   margin-bottom: 5%;
   padding-left: 11vw;
   display: flex;
   flex-direction:row;
   justify-content:space-between;
}

@media screen and (min-width: 768px) and (min-height: 1024px) {
   .ar-container { height: 100vh; }
   .rl-container { height: 100vh; }
   .ar-layout { height: auto }

}

@media screen and (max-width: 768px) {

   .ar-content-container {
   		width: 100%;
   		margin-left: 0;
   		padding-left: 5%;
   		padding-right: 5%;
   	}
		.rl-body { width: 86%; }

		.recipient-content-container {
			margin-top: 30vh !important;
			margin-left: 0px !important;
			margin-right: 0px !important;
			width: 100% !important;
			padding-left: 0px !important;
			padding-right: 0px !important;
		}

		.rl-top {
		   margin-top: 15%;
		}
}

@media screen and (max-width: 400px) {
		.add-recipient.ant-btn {
			margin-left: 0px !important;
			margin-top: 8px;
		}
}

.add-recipient.ant-btn {
   padding: 11.5px 32px;
   height: auto;
   border-radius: 0;
   font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
   border: 1px solid #154989;
	 background-color: #154989;
	 box-sizing: border-bo;
	 color: white;
	 margin-bottom: 8px;
	 height: 40px;
	 margin-left: 8px;
}


.ru-box {
	position: relative;
	margin-top: 20vh;
	margin-bottom: 10vh;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 2px;
	padding-bottom: 30px;
	background: #f2f2f2;
  }

  .recipient-layout {
		position: relative;
		width: 100%;
		background: #f2f2f2;
  }

  .recipient-content-container {
	position: relative;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
	flex: none;
	flex-direction: column;
	align-items: center;
	background: #fff;
	border-radius: 8px;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 0px;
	width: 80%;
	margin-top: 20vh;
	margin-bottom: 10vh;
  }

  .rp-tf-2 {
	width: 100%;
	margin-top: 15px;
	margin-bottom: 15px;
	margin-right: 10px;
 }

 .reci-table-body {
	position: relative;
	width: 100%;
	min-height: 500px;
	height: 100%;
	margin-top: 30px;
	padding-left: 0px;
	padding-right: 0px;
 }

 .ant-table-thead > tr {
    background-color: #3e3e3e;
}

.payment-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border-radius: 4px;
  padding-top: 20px;
  width: 50%;
  margin-top: 20vh;
  margin-bottom: 10vh;
  min-width: 400px;
}

.payment-content {
  display : flex;
  justify-content:center;
  margin-top: 18%;
  margin-bottom:5%;
}

.print-form-content-container {
  display: flex;
  justify-content: center;
  align-items : center;
  flex-direction: column;
  padding: 5%;
}

.payment-body  {
  position: relative;
  text-align: center;
  margin-top: 10px;
  width: 50%;
}

.payment-dropdown > .ant-form-item-children {
   position: relative;
}

.payment-description {
  display: flex;
  flex-direction:column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-important-text {
  color: red;
}

.payment-promo-parent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-promo-child {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.payment-promo-text {
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {

   .payment-content {
     margin-top: 40%;
   }
}

@media screen and (max-width: 800px) {
   .saved-p-body {
     padding-left: 0px;
     padding-right: 0px;
     height: 10%;
   }

   .payment-body {
     width: 80%;
   }
}

@media print {
  @page {
    /* Remove header and footer printing lines. */
    margin: 1mm 15mm 0 15mm;
  }
  html, body {
    /* Remove all the pages but first */
    height:100%;
    overflow: hidden;
  }
}

.tn-print-box {
	position: relative;
	display: flex;
	margin-top: 2vh;
	width: 100%;
	margin-bottom: 10px;
	justify-content: space-around;

}

.tn-print-box-right {
	flex: 2 1;
}

.tn-print-box-middle {
	flex: 1 1;
}

.tn-print-box-left {
	flex: 2 1;
}

.tn-pagination-box-item {
	margin-left: 0px;
	margin-right: 5px;
	height: 30px;
	min-width: 100px;
	font-size: 16px;
	color: #0b5ca9;
	border: 1px solid #0b5ca9;
	border-radius: 0px;

}

.print-btn.ant-btn {
	margin-bottom: 10%;
}

.back-btn.ant-btn {
	display: none;
}


@media screen and (max-width: 1024px) {
	.print-btn.ant-btn {
		display: none;
	}
	.back-btn.ant-btn {
		margin-bottom: 10%;
		display: block;
	}
}

.tr-item-content-container {
   position: relative;
   min-height: 500px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 15%;
   margin-bottom: 10%;
}

.tr-item-body {
   position: relative;
   height: 80vh;
   display: flex;
   justify-content:center;
   align-items:center;
}

.tr-details-body {
   position: relative;
   display: flex;
   flex-direction:column;
   width: 75%;
}

.tr-details-group-body {
   position: relative;
   display: flex;
   flex-direction:column;
   margin-bottom: 20px;
   background: white;
   padding:20px;
}

.tr-details-group-title {
   font-size: 24px;
   color: #3e3e3e;
   font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.tr-details-row-body {
   position: relative;
   display: flex;
   flex-direction:row;
   justify-content: space-between;
   margin-bottom: 20px;
   font-size: 16px;
   flex-wrap: wrap;
}

.tr-details-row-left-body {
  flex: 1 1;
  margin-right: 100px;
}

.tr-details-row-right-body {
  flex: 1 1;
  text-align: right;
}

.trl-layout {
   position: relative;
   width:100%;
   min-width: 800px;
   min-height: 800px;
   height: auto;
}

.trl-content-container {
   position: relative;
   width: 100%;
   min-width: 800px;
   min-height: 500px;
   margin-top: 20vh;
   padding: 0;
   background: #fff;
}

.trans-content-container {
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 80%;
  margin-top: 20vh;
  margin-bottom: 10vh;
}

.trl-select-recipient {
   position: relative;
   margin-top: 30px;
   width: 100%;
   text-align: center;
}

.trl-first-last-body {
   position: relative;
   display: flex;
   flex-direction:row;
   align-items:center;
   justify-content:space-around;
   width: 30%;
}

.trans-footer-box-parent {
   position: relative;
   width: 60%;
   margin-top: 30px;
   margin-bottom: 30px;
   margin-left: 20%;
   margin-right: 20%;
   display: flex;
}

.trans-footer-box1 {
    flex: 1 1;
}

.trans-footer-box2 {
  flex: 1 1;
}

.trans-footer-box3 {
  flex: 1 1;
}

.trans-footer-box4 {
  flex: 1 1;
}

.trans-footer-box5 {
  flex: 1 1;
}

.tr-error-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tr-error-title {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.tr-error-tree {
  width: 80%;

}

.tr-error {
  text-align: center;
  margin-bottom: 8px;
  width: 100%;
}


.trans-body {
   position: relative;
   width: 100%;
   min-height: 500px;
   height: 100%;
   margin-top: 30px;
   padding-left: 10px;
   padding-right: 10px;
}

@media screen and (max-height: 850px) {
   .trl-container { height: 100%; }
}

.zoom-out-layout {
  -moz-transform: scale(0.6, 0.6); /* Moz-browsers */
  zoom: 0.6; /* Other non-webkit browsers */
  zoom: 60%;
}

.zoom-in-layout {
  -moz-transform: scale(1, 1); /* Moz-browsers */
  zoom: 1; /* Other non-webkit browsers */
  zoom: 100%;
}

.trans-layout {
  position: relative;
  width: 100%;
  background: #f2f2f2;
}

.trans-desc-layout {
  position: relative;
  width: 100%;
  background: #f2f2f2;
}

.ant-spin-nested-loading {
  width: 100%;
}

.ant-table-tbody > tr:hover > td {
  background: #f2f2f2;
}

.ant-btn-primary {
  height: 46px;
  font-size: 16px;
  background-color: #0b5ca9;
  border: 1px solid #0b5ca9;
  max-width: 200px;
}

.tn-pagination-box {
	position: relative;
	display: flex;
	margin-top: 2vh;
	width: 100%;
	margin-bottom: 10px;
	justify-content: space-around;

}

.tn-pagination-box-right {
	flex: 1 1;
}

.tn-pagination-box-middle {
	flex: 4 1;
	text-align: center;
  margin-right: 8px;
  margin-left: 8px;
}

.tn-pagination-box-left {
	flex: 1 1;
}

.tn-pagination-box-item {
	margin-left: 0px;
	margin-right: 5px;
	height: 30px;
	min-width: 100px;
	font-size: 16px;
	color: #154989;
	border: 1px solid #154989;
	border-radius: 0px;

}

.trl-p-filter {
	margin-left: 8px;
	background: #154989;
	color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  border: 1px solid #154989;
  border-radius: 4px;
}

.ant-table {
  color: #0a0a0a;
  font-weight: 500;
}

.table-even-row {
  background-color: #DCF3F0;
}

.table-header-row {
  background-color: #3e3e3e;
}

.tr-number-message {
   margin-bottom: 8px;
   text-align: center;
   margin-right: 10vw;
   margin-left: 10vw;
   font-size: 24px;
}

.tr-number-check {
  width: auto;
  object-fit: contain;
  height: 100px;
  margin: 20px;
}

@media screen and (max-width: 900px) {
  .tr-item-content-container {
    margin-top: 35%;
  }
}

@media screen and (max-width: 500px) {
  .tr-item-content-container {
    margin-top: 45%;
  }
  .tr-number-message {
     font-size: 5vw;
  }
}

@media screen and (max-width: 768px) {
  .trl-p-filter {
    margin-right: 8px !important;
  	margin-left: 8px !important;
    margin-top: 0px !important;
  }
}

.trl-point-table .ant-table-thead > tr > th {
  padding: 16px;
  line-height: 16px;
  font-size: 16px;
  font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
  font-weight: 700;
  padding: 8px;
}

.trl-point-table .ant-table-tbody > tr > td {
    padding: 16px;
    font-size: 16px;
    font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
    font-style: normal;
    color: #666666;
    font-weight: 400;
}

@media screen and (max-width: 900px) {
  .trl-point-table .ant-table-thead > tr > th {
    padding: 8px;
    font-size: 13px !important;
  }

  .trl-point-table .ant-table-tbody > tr > td {
    padding: 8px;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 768px) {
  .trl-point-table .ant-table-thead > tr > th {
    font-size: 10px !important;
  }

  .trl-point-table .ant-table-tbody > tr > td {
    font-size: 10px !important;
  }

  .trans-content-container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 500px) {
  .trl-point-table .ant-table-thead > tr > th {
    font-size: 9px !important;
  }

  .trl-point-table .ant-table-tbody > tr > td {
    font-size: 9px !important;
  }
}

.search.ant-input-search.ant-input-affix-wrapper {
  width: auto;
  height: 40px;
}



  .log-container {
     position: relative;
     width: 100%;
     height: 100%;
     margin: 0;
     padding: 0;
  }

  .login-form-forgot {
      text-align: right;
      margin-bottom: 20px;
      flex: 1 1;
  }

  .log-line {
     line-height: 1.6;
  }

  .log-sub-line {
     line-height: 1.8;
  }

  .log-rmb-forgot-container{
    display: flex;
  }

  .log-content-container {
     position: relative;
     margin-left: auto;
     margin-right: auto;
     display: flex;
     flex: none;
     flex-direction: column;
     align-items: center;
     background: white;
     border-radius: 8px;
     padding: 20px;
     min-width: 30%;
     margin-top: 10vh;
     margin-bottom: 10vh;
  }

  .log-body {
     position: relative;
     text-align: center;
  }

  .log-remember {
    text-align: left;
     margin-bottom: 20px;
     flex: 1 1;
  }

  .log-btn {
     margin-right: 10px;
     background-color: #57a6d0;
     border: 1px solid #57a6d0;
     width: 100%;
     border-radius: 4px;
     padding: 10px;
     height: 40px;
  }

  .log-btn-calculate :hover{
    margin-right: 10px;
    background-color: #57a6d0;
    border: 1px solid #57a6d0;
    width: 200px;
    border-radius: 4px;
    padding: 10px;
    height: 40px;
  }

  .log-custom-footer.ant-layout-footer {
     padding: 0;
     width: 100%;
     bottom: 0;
     position: static;
  }

  .log-button2 {
     margin-right: 25px;
     background-color: Transparent;
     margin-bottom: 20px;
     border: 1px solid #57a6d0;
     width: 100%;
     border-radius: 4px;
     padding: 10px;
     height: 40px;
  }

  .signup-button {
    margin-right: 25px;
    background-color: #fff;
    margin-bottom: 20px;
    border: 1px solid #57a6d0;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    height: 40px;
  }

  @media screen and (min-width: 320px) {
     .log-container { height: 100vh; }
  }

  @media screen and (min-height: 500px) {

     .log-body {
        margin-left: 5%;
        margin-right: 5%;
     }

     .log-custom-footer.ant-layout-footer {

     }
  }

  @media screen and (max-width: 768px) {
     .log-content-container {
       max-width: 300px;
     }
  }

  @media screen and (max-width: 320px), screen and (max-height: 320px) {
     .log-custom-footer.ant-layout-footer {
        position: static;
     }
  }
  .or {
      width: 100%;
      height: 51px;
      text-align: center;
      font-family: BentonSans, 'Rubik-1', 'Lato', Arial, sans-serif;
      font-weight: 400;
      font-size: 22px;
      letter-spacing: normal;
      line-height: 31px;
      margin-top: 50px;
      border-top: 1px solid #616161;
  }

  .lines {
    width: 100%;
    height: 51px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: Helvetica, 'Didact Gothic', 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
  }
  .lines:before {
    margin-left: -100%;
  }
  .lines:after {
    margin-right: -100%;
  }
  .lines:before, .lines:after {
    display: inline-block;
    content: '';
    background-color: #bfbfbf;
    height: 1px;
    width: 100%;
    vertical-align: middle;
    box-sizing: border-box;
    border: solid #FFF;
    border-width: 0 10px;
  }

