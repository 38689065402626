.ar-add-recipient {
	position: relative;
	margin-top: 30px;
	width: 100%;
	text-align: center;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rl-layout {
	position: relative;
	width:100%;
	min-width: 1000px;
	min-height: 800px;
	height: auto;
	margin-top: 10vh;
}

.ar-content-container {
	position: relative;
	width: 100%;
	padding-left: 20%;
	padding-right: 20%;
}

.ar-body {
	position: relative;
	margin-top: 10px;
}

.rl-container {
	position: relative;
	width: 100%;
	margin: 0;
	min-width: 800px;
}

.rl-content-container {
	position: relative;
	width: 100%;
	min-width: 800px;
	min-height: 500px;
	margin: 0;
	padding: 0;
}

.rl-select-recipient {
	position: relative;
	text-align: left;
	font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
}

.rl-body {
	position: relative;
	width: 75%;
	min-height: 500px;
	height: 100%;
	margin-top: 30px;
	margin-left: 13vw;
}

.rl-top {
   position: relative;
   display: flex;
   justify-content:space-between;
   width: 100%;
   margin-top: 5%;
	 flex-wrap: wrap;
}

.rl-p-add-recipient {
	margin-left: 8px;
	background: #0b5ca9;
	color: #fff;
}

.rl-p-search-add-right {
	position: relative;
	text-align: right;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.rl-first-last-body {
   position: relative;
   display: flex;
   flex-direction:row;
   align-items:center;
   justify-content:space-around;
   width: 30%;
}

.pagination-box {
	position: relative;
	display: flex;
	margin-top: 2vh;
	width: 100%;
	margin-bottom: 10px;
	justify-content: space-around;

}

.pagination-box-right {
	flex: 1;
}

.pagination-box-middle {
	flex: 4;
	text-align: center;
	margin-left: 8px;
	margin-right: 8px;
}

.pagination-box-left {
	flex: 1;
}

.pagination-box-item {
	margin-left: 0px;
	margin-right: 5px;
	height: 30px;
	min-width: 100px;
	font-size: 16px;
	color: #154989;
	border: 1px solid #154989;
	border-radius: 0px;

}

.rl-prev-next-body {
   position: relative;
   width: 92%;
   margin-top: 30px;
   margin-bottom: 5%;
   padding-left: 11vw;
   display: flex;
   flex-direction:row;
   justify-content:space-between;
}

@media screen and (min-width: 768px) and (min-height: 1024px) {
   .ar-container { height: 100vh; }
   .rl-container { height: 100vh; }
   .ar-layout { height: auto }

}

@media screen and (max-width: 768px) {

   .ar-content-container {
   		width: 100%;
   		margin-left: 0;
   		padding-left: 5%;
   		padding-right: 5%;
   	}
		.rl-body { width: 86%; }

		.recipient-content-container {
			margin-top: 30vh !important;
			margin-left: 0px !important;
			margin-right: 0px !important;
			width: 100% !important;
			padding-left: 0px !important;
			padding-right: 0px !important;
		}

		.rl-top {
		   margin-top: 15%;
		}
}

@media screen and (max-width: 400px) {
		.add-recipient.ant-btn {
			margin-left: 0px !important;
			margin-top: 8px;
		}
}

.add-recipient.ant-btn {
   padding: 11.5px 32px;
   height: auto;
   border-radius: 0;
   font-family: Helvetica, 'Didact Gothic', 'Lato', Arial, sans-serif;
   border: 1px solid #154989;
	 background-color: #154989;
	 box-sizing: border-bo;
	 color: white;
	 margin-bottom: 8px;
	 height: 40px;
	 margin-left: 8px;
}


.ru-box {
	position: relative;
	margin-top: 20vh;
	margin-bottom: 10vh;
	padding-left: 10%;
	padding-right: 10%;
	padding-top: 2px;
	padding-bottom: 30px;
	background: #f2f2f2;
  }

  .recipient-layout {
		position: relative;
		width: 100%;
		background: #f2f2f2;
  }

  .recipient-content-container {
	position: relative;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
	flex: none;
	flex-direction: column;
	align-items: center;
	background: #fff;
	border-radius: 8px;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 0px;
	width: 80%;
	margin-top: 20vh;
	margin-bottom: 10vh;
  }

  .rp-tf-2 {
	width: 100%;
	margin-top: 15px;
	margin-bottom: 15px;
	margin-right: 10px;
 }

 .reci-table-body {
	position: relative;
	width: 100%;
	min-height: 500px;
	height: 100%;
	margin-top: 30px;
	padding-left: 0px;
	padding-right: 0px;
 }

 .ant-table-thead > tr {
    background-color: #3e3e3e;
}
