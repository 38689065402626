.su-content-container {
   position: relative;
   display: flex;
   flex-direction:row;
   width: 100%;
   align-items:center;
   justify-content: center;
}

.signup-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border-radius: 0px;
  padding: 20px;
  width: 60%;
  margin-top: 15vh;
  margin-bottom: 10vh;
}

.log-body2 {
  position: relative;
  text-align: center;
  margin-top: 60px;
  width: 60%;
}

.signup-body {
  position: relative;
  text-align: center;
  margin-top: 10px;
  width: 80%;
}


@media screen and (max-width: 991px) {
  /* start of large tablet styles */
  .signup-content-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: center;
    background: #f2f2f2;
    border-radius: 0px;
    padding: 15px;
    width: 70%;
    margin-top: 15vh;
    margin-bottom: 10vh;
  }

  .signup-body {
    position: relative;
    text-align: center;
    margin-top: 10px;
    width: 90%;
  }
  }

  @media screen and (max-width: 767px) {
    .signup-content-container {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      background: #f2f2f2;
      border-radius: 0px;
      padding: 10px;
      width: 80%;
      margin-top: 15vh;
      margin-bottom: 10vh;
    }
  .signup-body {
    position: relative;
    text-align: center;
    margin-top: 10px;
    width: 95%;
  }
  }

  @media screen and (max-width: 479px) {
    .signup-content-container {
      position: relative;
      margin-left: 2%;
      margin-right: 2%;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      background: #f2f2f2;
      border-radius: 0px;
      padding: 10px;
      width: 95%;
      margin-top: 10vh;
      margin-bottom: 10vh;
    }
    .signup-body {
      position: relative;
      text-align: center;
      margin-top: 10px;
      width: 95%;
    }
  }


.su-layout {
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, rgb(41, 108, 187), #154989);
}

.su-box1 {
  flex: 1;
}

.su-box2 {
  position: relative;
  background: #f2f2f2;
  padding: 40px 240px;
  text-align: left;
  flex: 2;
}

.su-box3 {
  flex: 1;
}

.su-open-account {
   text-align: center;
   margin-bottom: 40px;
   font-size: 20px;
}

.su-upper-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.su-mid-title-text {
  width: 100%;
   text-align: center;
   margin-top: 40px;
   margin-bottom: 20px;
   font-size: 20px;
}

.su-left-title-text {
   width: 100%;
   text-align: left;
   margin-top: 20px;
   margin-bottom: 10px;
   font-size: 20px;
}

.su-address-text {
   text-align: center;
   width: 100%;
   margin: 20px;
   font-size: 20px;
}

.su-body {
   margin: auto;
   width: 100%;
   height: 100%;
   padding-left: 18%;
   padding-right: 18%;
   padding-top: 5%;
   padding-bottom: 5%;
}

.su-body-2 {
   position: relative;
   width: 70%;
   display: flex;
   flex-direction: column;
   background-color: whitesmoke;
   margin-top: 60px;
   margin-bottom: 60px;
}

.su-image-container {
   position: relative;
   width: auto;
   max-width:20%;
   height: 120vh;
   right: 0;
}

.su-item-2 {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.su-item-upload-text {
  margin: 30px;
  font-size: 20px;
  text-align: center;
}

.su-item-upload {
   width: 100%;
   position: relative;
}

.su-btn-sign-up {
   width: 100%;
}

.su-tf {
   width: 40%;
   margin-top: 10px;
   margin-right: 10px;
}

.su-fieldset {
  border-bottom: 1px solid #dedede;
  padding-top: 20px;
  padding-bottom: 40px;
}

.su-tf-2 {
   width: 100%;
   margin-top: 15px;
   margin-bottom: 15px;
   margin-right: 10px;
}

.ant-calendar-picker-input {
    height: 46px;
    border-radius: 0px;
}

.ant-dropdown-trigger, .dropdown-item {
  height: 40px;
  background: #fff;
  border: 1px solid #ced4da
}

.su-upload > .ant-upload.ant-upload-select-picture-card {
   margin-top: 10px;
   width: 100%;
}

.su-image {
   width: 50%;
}

.su-smile-guy {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.su-custom-row.ant-row.ant-form-item {
   width: 48%;
   margin-right:0px;
}

.su-custom-row-full.ant-row.ant-form-item {
   width: 100%;
}

.su-autocomplete-row-full {
  width: 50%;
}

.su-row-full {
  width: 100%;
}

.su-custom-btn-create-account.ant-btn.ant-btn-primary {
   width: 100%;
}

.su-address-item {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
}

.su-bread-winner-title-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.su-bread-winner-text-title {
  font-size: 20px;
}

.su-bread-winner-note-title {
  font-size: 11px;
  color: red;
}

@media screen and (max-width: 1024px) and (min-width: 768px)  {
   .su-custom-btn-create-account-row { width: 50%; }
}

@media screen and (max-width: 1024px) {
   .su-body { padding-left: 5%; padding-right: 5%; }
}

@media screen and (max-width: 800px) {
  .su-body-2 { width: 100%; padding-left: 5%; padding-right: 5%; }
}

@media screen and (max-width: 768px) {
   .su-custom-row.ant-row.ant-form-item{ width: 100%; }
   .su-image-container{ display: none; }

}

.pg-appintment__title {
  color: #00AC1F; }

.pg-appintment__box {
  margin-top: -30px; }

.su-row {
  width: 100%;
  display: flex;
  padding: 20px 20px;
  margin: 0px auto;
}

.su-column1 {

}

.su-column2 {

}

.su-column3 {

}

.form-control {
  height: 46px;
  border-radius: 0px;
}

.su-btn-register {
  margin-right: 10px;
  background-color: #57a6d0;
  border: 1px solid #57a6d0;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  height: 46px;
}
