body {
  color: #777;
  font-family: Helvetica, "Didact Gothic", "Poppins", Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.625em;
  position: relative;
}
h1,
h2 {
  font-weight: 400;
  line-height: 1.25;
}

h1 {
  font-size: 2.4rem;
}
h1,
h2,
h3,
h4 {
  margin: 0 0 16px;
  text-transform: none;
}
.html2canvas-container {
  width: 3000px !important;
  height: 3000px !important;
}
.home-container {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

.home-top-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: black;
}

.home-top-bg-carousel {
  height: 100vh;
}

.home-top-bg-1 {
  background-image: url(../images/sasai-remit-banner.png);
  background-size: cover;
  background-position: center center;
  align-items: center;
  justify-content: center;
  height: 88vh;
}

.home-top-bg-1-child {
  /* background-image: url(../images/two-people-pins.png); */
}

.home-top-bg-2 {
  background-image: url(../images/EcoCash-Remit-Banner-2.png);
  background-size: cover;
  background-position: center center;
  align-items: center;
  justify-content: center;
  height: 88vh;
}

.home-top-bg-4 {
  background-image: url(../images/NCEA-website-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 40px;
  align-items: center;
  justify-content: center;
  height: 88vh;
}

.home-parent-body {
  width: 80%;
  height: 100%;
  position: absolute;
  display: flex;
}

.home-middle-container-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  height: 60vh;
  padding-left: 10%;
  padding-right: 10%;
}

.home-middle-container {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-payment-container {
  width: 100%;
  position: relative;
  background-color: #f7f7f7;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
}

.home-payment-options-container {
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.home-last-sub-logo-container {
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}

.home-visa-master-logo {
  max-width: 578px;
  height: auto;
  margin-left: 4px;
  margin-right: 4px;
  object-fit: contain;
}

.home-last-sub-logo {
  width: 150px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: contain;
}

.home-last-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.home-stories-container {
  position: relative;
  display: flex;
  width: 30vw;
  height: 100%;
  min-height: 300px;
  flex-direction: column;
  margin-right: 20px;
  padding: 20px;
}

.home-stories-img {
  background-image: url(../images/mother-daughter.jpg);
  background-size: cover;
  background-position: center center;
  height: auto;
  width: 30%;
}

.home-divider {
  position: relative;
  width: 85%;
  height: 1px;
  background-color: #666666;
  margin-top: 8px;
  margin-bottom: 8px;
}

.home-content-container2.ant-layout-content {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.home-layout {
  position: relative;
  width: 100%;
  background: transparent;
}

.home-layout-auto {
  width: 100%;
  height: 100%;
}

.home-calculate-layout.ant-layout {
  position: relative;
  width: 100%;
  height: auto;
  background: linear-gradient(to right, #0c5ca9, #57a6d0);
}

.home-image-container {
  position: relative;
  width: 100%;
  top: 0;
  bottom: 0;
}

.home-left-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-left-parent {
  flex-direction: column;
  flex: 1;
}

.home-right-parent {
  flex: 1;
}

.home-left-text {
  color: white;
}

.home-head-right-text {
  color: white;
  text-align: center;
}

.home-right-text {
  color: #676767;
}

.home-right-text-get {
  color: #20409a;
  font-size: 20px;
}

.home-right-text-transfer-fees {
  color: #20409a;
  font-size: 15px;
}

.home-right-text-area-2 {
  position: relative;
  display: flex;
  flex-direction: column;
}

.home-right-top-parent {
  position: relative;
  width: 100%;
}

.home-right-discount-icon {
  height: 10vh;
}

.home-right-discount-area {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
}

.home-right-offer-area {
  position: relative;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5vh;
  opacity: 0.7;
}

.home-rate {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.home-right-text-area {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 40%;
  padding: 40px;
  margin-bottom: 5%;
  justify-content: space-around;
}

.home-right-desc {
  display: none;
}

.home-right-desc-text {
}

.home-left-text-area {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 40%;
  padding: 40px;
  top: 20%;
  justify-content: space-around;
}

.home-content-container.ant-layout-content {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
}

.home-body.ant-form {
  position: relative;
  width: 100%;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-btn-calculate {
  left: 0;
}

.home-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.home-custom-row.ant-row.ant-form-item {
  width: 100%;
}

.custom-d.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  width: 5vh;
}

.custom-header.ant-layout-header {
  padding: 0;
  margin: 0;
  line-height: normal;
  background: transparent;
}

.custom-header2.ant-layout-header {
  padding: 0;
  height: auto;
  margin: 0;
  line-height: normal;
  background: transparent;
  position: fixed;
}

/*.quote-custom-footer.ant-layout-footer {
   padding: 0;
   position: relative;
   bottom: 0;
}*/

.custom-footer.ant-layout-footer {
  padding: 0;
  width: 100%;
  bottom: 0;
}

.home-collect.ant-btn {
  padding: 11.5px 32px;
  height: auto;
  border-radius: 0;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  border: 1px solid #154989;
}

.home-mobile-store.ant-btn {
  padding: 11.5px 32px;
  height: auto;
  border-radius: 0;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  background: #46c2cF;
  border: 1px solid #46c2cF;
  color: white;
  letter-spacing: 2px;
  font-size: 16px;
  margin: 8px;
}

.dropdown.ant-btn {
  width: 40vh;
}

.home-custom-dropdown {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}

.dropdown-item.ant-dropdown-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.home-currency-amount-area {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.home-currency-amount-area {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.home-quote {
  margin-top: 40px;
  margin-bottom: 20px;
}

.flag-country {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flag-country-name {
  margin-left: 8px;
}

.home-banner-carousel {
  margin-top: 60px;
}

.home-top-carousel .ant-carousel .slick-slider {
  height: 100%;
}

.home-top-carousel .ant-carousel .slick-slide {
  text-align: center;
  width: 100%;
  height: 100%;
  line-height: 100px;
  overflow: hidden;
}

.home-top-carousel .ant-carousel .slick-slide h3 {
  color: #fff;
}

.home-top-title {
  color: #154989;
  text-align: left;
  width: 100%;
  /* font-size: 70px; */
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: 0;
  /* font-family: 'Oswald', sans-serif; */
  font-family: "Rubik", sans-serif;
  transform-origin: 50% 50% 0px;
}

.home-top-title-green {
  /* color : rgb(48, 87, 30); */
  color: white;
  text-align: left;
  width: 100%;
  /* font-size: 88px; */
  font-size: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: 0px;
  /* font-family: 'Oswald', sans-serif; */
  font-family: "Rubik", sans-serif;
  line-height: 110px;
  font-style: normal;
}

@media screen and (max-height: 700px) {
  .home-top-title-green {
    font-size: 13vh;
    line-height: 13vh;
  }
}

.home-top-desc {
  color: #154989;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  line-height: 24px;
  /* font-family: 'Merriweather', Arial, sans-serif; */
  font-family: "Raleway", "Merriweather", Arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  /* font-style: italic; */
}

.home-top-desc-green {
  /* color : rgb(78, 140, 50); */
  /* color : rgb(48, 87, 30); */
  color: #154989;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  /* font-family: 'Merriweather', sans-serif; */
  font-family: "Raleway", "Merriweather", Arial, sans-serif;
  line-height: 24px;
  font-size: 24px;
  font-weight: 400;
  /* font-style: italic; */
}

.home-top-ecocash {
  text-align: left;
  color: white;
  font-weight: 700;
  letter-spacing: 8px;
  font-size: 18px;
  font-family: BentonSans, "Rubik", "Lato", sans-serif;
  line-height: 21px;
}

.home-top-ecocash-green {
  text-align: left;
  color: rgb(100, 100, 100);
  font-weight: 700;
  letter-spacing: 8px;
  font-size: 18px;
  font-family: BentonSans, "Rubik", "Lato", sans-serif;
  line-height: 21px;
}

.home-partner-list {
  display: flex;
  overflow: scroll;
  flex-direction: row;
  justify-content: space-between;
  padding: 3%;
}

.home-partner-logo-start {
  object-fit: contain;
}

.home-partner-logo {
  margin-left: 8vw;
  object-fit: contain;
}

.home-collect-area {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50vh;
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  font-weight: "400";
}

.home-collect-area-middle {
  margin-top: 20px;
  margin-bottom: 40px;
  line-height: 1.5;
  text-align: center;
}

.home-choose-area {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 80px;
}

.home-choose-icons-area {
  width: 80%;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
}

.home-choose-icons-child-area {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.home-choose-icon {
  height: 100px;
  width: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  /* border: 5px solid #154989; */
}

@media screen and (max-width: 768px) {
  .home-content-container {
    margin-left: 30%;
    margin-right: 30%;
  }
  .quote-body.ant-form {
    width: 80%;
    padding: 6%;
    margin: 3vh;
  }
  .home-top-container {
    width: 100%;
    height: 120vh;
  }
  /*.ant-carousel { display : none }*/
  .home-item {
    padding-left: 3%;
    padding-right: 3%;
  }
}

.home-mid-video {
  display: block;
}

@media screen and (max-height: 400px) {
  .home-top-container {
    height: 200vh;
  }
}

@media screen and (max-width: 1024px) {
  .home-custom-row.ant-row.ant-form-item {
    width: 100%;
  }
  .home-left-text-area {
    top: 10%;
  }

  .home-last-container {
    display: none;
  }

  .home-layout-auto {
    background-size: cover;
    background-position: center center;
  }
  .home-top-title {
    font-size: 5vh;
  }
  .home-top-desc {
    font-size: 16px;
  }
  .home-top-title-green {
    font-size: 5vh;
    line-height: 8vh;
  }
  .home-top-desc-green {
    font-size: 16px;
  }
  .home-top-ecocash {
    font-size: 15px;
    letter-spacing: 6px;
  }
  .home-top-ecocash-green {
    font-size: 15px;
    letter-spacing: 6px;
  }
}

@media screen and (max-height: 800px) {
  /* .home-top-title {
    font-size : 13px;
  } */

  .home-top-desc {
    font-size: 16px;
  }
  .home-top-desc-green {
    font-size: 16px;
  }

  .home-top-ecocash {
    font-size: 15px;
    letter-spacing: 4px;
  }
  .home-top-ecocash-green {
    font-size: 15px;
    letter-spacing: 4px;
  }
}

@media screen and (max-width: 700px) {
  /* .home-top-title {
    font-size : 13px;
  } */

  .home-top-desc {
    font-size: 13px;
  }
  .home-top-desc-green {
    font-size: 13px;
  }

  .home-top-ecocash {
    font-size: 9px;
    letter-spacing: 4px;
  }
  .home-top-ecocash-green {
    font-size: 9px;
    letter-spacing: 4px;
  }
}

@media screen and (max-width: 800px) {
  .home-content-container2.ant-layout-content {
    flex-direction: column;
  }
  .home-right-text-area {
    width: 80%;
    margin-top: auto;
    margin-left: 10%;
    background-color: #f0f2f5;
    padding-bottom: 20px;

    border-radius: 8px;
  }
  .home-left-parent {
    width: 100%;
    right: 0;
    margin-bottom: 0;
    margin-top: 8%;
  }
  .home-body.ant-form {
    width: 100%;
    right: 0;
  }

  .home-right-parent {
    display: none;
  }
  .home-right-desc {
    display: block;
    text-align: center;
  }
  .home-right-desc-text {
    font-size: 10px;
  }
  .home-right-parent {
    width: 90%;
  }

  .home-content-container.ant-layout-content {
    margin-left: 5%;
    margin-right: 5%;
  }

  .home-payment-options-container {
    padding: 0;
    border-width: 4px;
  }

  .home-last-sub-logo-container {
    width: 80%;
  }

  .home-visa-master-logo {
    width: 200px;
    height: 80px;
  }

  .home-last-sub-logo {
    width: 100px;
    height: 40px;
  }
  .home-mid-video {
    display: none;
  }
  .home-app-area-sub-title-area {
    width: 80%;
  }
  .home-app-area {
    min-height: 80vh !important;
  }
  .home-collect-area {
    height: 80vh !important;
  }
  .home-right-text-area {
    top: 10%;
  }
  .home-body.ant-form {
    position: relative;
  }
  .container-right-image {
    display: none !important;
  }
  .containers {
    margin-top: 20%;
    width: 70% !important;
  }
}

.gen-error-img {
  width: 300px;
  height: 300px;
}

.gen-error-parent {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quote-limit-text {
  color: #b6b6b4;
  font-size: 10px;
  margin-bottom: 8px;
}

.quote-currency-text {
  font-size: 13px;
  margin-bottom: 8px;
}

.container-right-image {
  object-fit: contain;
  height: 65vh;
  width: 65vw;
  margin-top: 10%;
  margin-right: 5%;
}

@media screen and (max-height: 900px) {
  .home-right-text-area {
    padding: 5px;
    width: 90%;
    margin-top: 10%;
    top: 0;
  }

  .home-right-text-area {
    position: relative;
  }
}

@media screen and (max-width: 1024px) {
  .container-right-image {
    margin-top: 20%;
    height: 40vh;
    width: 40vw;
  }
}

@media screen and (min-width: 801px) {
  .home-right-text-area {
    top: 25%;
  }
}

@media screen and (max-width: 600px) {
  .home-payment-options-container {
    width: 80%;
  }
}

@media screen and (max-height: 600px) {
  .home-right-text-area {
    padding: 5px;
    width: 90%;
    margin-top: 10%;
    top: 0;
  }

  .home-right-text-area {
    position: relative;
  }
}

@media screen and (min-height: 560px) {
  .home-layout {
    height: 100%;
  }

  .home-calculate-layout.ant-layout {
    height: 100%;
  }
}

.home-main-container {
  display: flex;
}

.getStarted--button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid rgba(59, 172, 240);
  border-radius: 5px;
  color: rgba(59, 172, 240);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
  padding: 11px 16px 8px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: background-color 0.25s linear, border-color 0.25s linear,
    color 0.25s linear;
  vertical-align: middle;
  white-space: nowrap;
}

.www-Button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  width: 200px;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.25s ease, border 0.25s ease, color 0.25s ease;
  vertical-align: middle;
  white-space: nowrap;
}
.www-Button--primaryInverse {
  background-color: #fff;
  border: none;
  color: rgba(59, 172, 240);
}

.bui-u-mt--medium {
  margin-top: 24px !important;
}

.Text--regular {
  font-size: 20px;
}

.Opacity--80 {
  opacity: 0.8;
}
.bui-u-mb--large {
  margin-bottom: 32px !important;
}
.bui-u-textWhite {
  color: #fff !important;
}

.Home-heroHeader {
  font-size: 50px;
  letter-spacing: -1.55px;
  line-height: 1.18;
}

.bui-u-mb--regular {
  margin-bottom: 16px !important;
}
.bui-u-textWhite {
  color: #fff !important;
}
.relative {
  position: relative;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.banner-area .overlay-bg {
  background-color: rgba(59, 172, 240, 0.4);
}
.banner-content {
  text-align: left;
}
.banner-content h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1em;
  margin-top: 20px;
}
.banner-content h1 span {
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .banner-content h1 {
    font-size: 36px;
  }
}
@media (max-width: 991.98px) {
  .banner-content h1 br {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .banner-content h1 {
    font-size: 45px;
  }
}
@media (max-width: 414px) {
  .banner-content h1 {
    font-size: 32px;
  }
}
@media (max-width: 1280px) {
  .banner-content br {
    display: none;
  }
}
@media (max-width: 763px) {
  .banner-content p {
    color: #fff;
  }
  .banner-area {
    margin-top: 18%;
  }
  .home-app-area {
    min-height: 100vh;
  }
}

.containers {
  width: 30%;
  margin-left: 8%;
  position: relative;
  text-align: center;
  align-self: center;
  margin-top: 0;
}

/**
About Area
*/
.about-area__content h4 {
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #474747;
}

.about-area__content h3 {
  color: #303030;
  text-transform: uppercase;
  padding-right: 50px;
  margin-bottom: 25px;
}

.about-area__content a.cr-btn {
  margin-top: 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-area__image {
    padding: 50px 0;
    text-align: center;
    max-height: auto;
  }
  .about-area__image img {
    position: relative;
  }
  .about-area__content {
    padding: 0;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .about-area__image {
    padding: 50px 0;
    text-align: center;
    max-height: auto;
  }
  .about-area__image img {
    max-width: 100%;
    position: relative;
  }
  .about-area__image img {
    max-width: 100%;
  }
  .about-area__content {
    padding: 0;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .home-top-bg-1 {
    background-image: url(../images/EcoCash-Banner-mobile.jpg);
  }

  .home-top-desc-green {
    color: white;
  }

  .home-top-bg-2 {
    background-position: 80% center;
  }
}

@media only screen and (max-width: 575px) {
  .about-area__content {
    padding-left: 0;
    padding-right: 0;
  }
  .about-area__content h3 {
    padding-right: 0;
    padding-left: 0;
  }
}

/* --------------------------------

Primary style

-------------------------------- */
.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}
.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}
.cd-words-wrapper b.is-visible {
  position: relative;
}
.no-js .cd-words-wrapper b {
  opacity: 0;
}
.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* --------------------------------

xslide

-------------------------------- */
.cd-headline.slide span {
  display: inline-block;
}
.cd-headline.slide .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
  width: auto !important;
}
.cd-headline.slide b {
  opacity: 0;
  top: 0.2em;
}
.cd-headline.slide b.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in 0.6s;
  -moz-animation: slide-in 0.6s;
  animation: slide-in 0.6s;
}
.cd-headline.slide b.is-hidden {
  -webkit-animation: slide-out 0.6s;
  -moz-animation: slide-out 0.6s;
  animation: slide-out 0.6s;
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}
@-moz-keyframes slide-out {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  60% {
    opacity: 0;
    -moz-transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    -moz-transform: translateY(120%);
    -ms-transform: translateY(120%);
    -o-transform: translateY(120%);
    transform: translateY(120%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.color--theme {
  color: rgba(59, 172, 240);
}

.home-app-area {
  min-height: 50vh;
  background-color: #154989;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-app-area-title-text {
  line-height: 1.5;
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 18px;
  font-family: BentonSans, "Rubik", "Oswald", sans-serif;
  margin-top: 20px;
}

.home-app-area-sub-title-text {
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 48px;
  text-align: center;
  font-family: BentonSans, "Rubik", "Lato", Arial, sans-serif;
}

.home-app-area-sub-title-area {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.home-app-area-sub-button {
  padding: 20px;
  padding-bottom: 20px;
  background-color: #154989;
  color: white;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
}

.transaparent-button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin: 0;
  padding: 0;
}

.transaparent-button.ant-btn:hover {
  background-color: transparent;
}

.send-money-white-button.ant-btn {
  background-color: transparent;
  box-sizing: border-box;
  color: #154989;
  margin-bottom: 8px;
  margin-right: 20px;
  font-size: 17px;
  padding: 11.5px 32px;
  height: auto;
  border-radius: 4px;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  border: 2px solid #154989;
}

.send-money-green-button.ant-btn {
  /* background-color: rgb(78, 140, 50); */
  background-color: #154989;
  /* background-color : rgb(48, 87, 30); */
  box-sizing: border-box;
  color: white;
  margin-bottom: 8px;
  margin-right: 20px;
  border: 1px solid white;
  font-size: 17px;
  padding: 11.5px 32px;
  height: auto;
  border-radius: 0;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  /* border: 1px solid rgb(78, 140, 50); */
  border: 1px solid #154989;
}

.learn-more-button.ant-btn {
  background-color: white;
  box-sizing: border-box;
  color: #154989;
  margin-bottom: 8px;
  border: 1px solid white;
  font-size: 17px;
  padding: 11.5px 32px;
  height: auto;
  border-radius: 4px;
  font-family: Helvetica, "Didact Gothic", "Lato", Arial, sans-serif;
  border: 1px solid white;
}

.addon-body {
  position: relative;
  width: 100%;
  min-height: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 28vh;
}

@media screen and (max-width: 768px) {
  .home-app-area-sub-title-text {
    font-size: 40px;
  }
  .home-app-area-title-text {
    letter-spacing: 0.3em;
  }
}

@media screen and (max-width: 500px) {
  .home-app-area-sub-title-text {
    font-size: 38px;
  }
}
