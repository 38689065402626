.user-container {
   position: relative;
   width:100%;
   height: 100vh;
}

.user-container-2 {
   position: relative;
   width:100%;
   height: 100vh;
   margin: 0;
}

.user-layout {
   position: relative;
   width:100%;
}

.user-anchor.ant-anchor-wrapper {
  position: absolute;
  background-color:rgba(255, 255, 255, 0.4);
  margin-top:60px;
  margin-left:20px;
  margin-right: 0;
  padding:8px;
}

.user-content-container {
   position: relative;
   width: 100%;
   margin-bottom: 80px;
   display: flex;
   flex-direction:column;
}

.user-wrap-content-container {
   position: relative;
   display: flex;
   flex-direction:column;
   align-items: center;
   width: 60%;
   align-self: center;
}

.user-content-container-2 {
   position: relative;
   margin-left: 30%;
   margin-right: 30%;
   display: flex;
   flex-direction: column;
   align-items:center;
   justify-content: center;
   height: auto;
   margin-top: 10%;
   margin-bottom: 5%;
   background: white;
}

.user-alert {
   margin-top: 20px;
   text-align: center;
}

.user-saved-cards-body {
   position: relative;
   width: 100%;
   height: 100%;
   padding-top: 20px;
   padding-bottom: 30px;
}

.fica-box {
  position: relative;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 80%;
  margin-top: 200px;
  background: #f2f2f2;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}

.user-document-management-body {
   position: relative;
   width: 100%;
   height: 100%;
   padding-top: 30px;
}

.user-saved-cards-item {
   background: #FFFFFF;
}

.user-body.ant-form.ant-form-horizontal {
   position: relative;
   width:100%;
   height: 100%;
}

.documents-box {
  position: relative;
  height: 100%;
  width: 80%;
  margin-left: 10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  padding-bottom: 30px;
  background: #f2f2f2;
}

.documents-box-form {
  padding-left: 20%;
  padding-right: 20%;

}

.profile-body.ant-form.ant-form-horizontal {
  position: relative;
  align-content: center;
  align-items: center;
  margin-left: 20%;
  width:60%;
  height: 100%;
}

.user-password-menu {
  position: relative;
  margin: auto;
  width: 100%;
}

.user-password-menu-item-text {
  text-align: center;
}

.user-password-menu-item {
  width: 50%;
}

.user-password-body.ant-form.ant-form-horizontal {
  background: white;
  position: relative;
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction:column;
  justify-content:center;
  padding: 40px;
}

.user-item-2 {
   position: relative;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.user-phone-verification {
   position: relative;
   display: flex;
   width: 100%;
   justify-content: center;
}
.user-phone-child {
   position: relative;
   flex-direction: row;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}

.user-phone-left-button {
  margin-right: 8px;
}


.user-tf {
   width: 40%;
   margin-top: 10px;
   margin-right: 10px;
}

.user-tf-2 {
   width: 100%;
   margin-top: 10px;
   margin-right: 10px;
}

.user-custom-row.ant-row.ant-form-item {
   width: 48%;
   margin-right:8px;
}

.user-custom-row-full.ant-row.ant-form-item {
   width: 100%;
}

.user-custom-btn-row.ant-row.ant-form-item {
   width: 100%;
   padding: 11.5px;
   height: auto;
   border-radius: 0;
}

.user-custom-btn.ant-btn.ant-btn-primary {
   width: 100%;
   padding: 11.5px;
   height: auto;
   border-radius: 0;
   background: #154989;
   border: 1px solid #154989;
   margin: 8px;
}

.user-title {
   position: relative;
   padding-top: 30px;
   width: 100%;
   text-align: left;
   font-family: BentonSans, 'Rubik', 'Lato', sans-serif;
}

.user-selfie-desc {
  margin-bottom: 30px;
}

.user-reset-password-result-text {
   margin-bottom: 20px;
}

.user-fica-parent {
   display: flex;
   flex-direction:column;
   align-items:center;
}

.user-fica-text {
   margin-top: 8px;
   margin-bottom: 8px;
   text-align: center;
}

.user-fica-note-text {
   margin-top: 20px;
   margin-bottom: 20px;
   text-align: center;
   font-size: 13px;
}

.user-fica-limit-text {
   margin-top: 40px;
   margin-bottom: 40px;
   text-align: center;
}

.user-fica-parent-details {
   display: flex;
   flex-direction:row;
   justify-content: space-between;
   flex-wrap: wrap;
   margin-left: 10vw;
   margin-right: 10vw;
}

.user-fica-parent-child-details {
   display: flex;
   flex-direction:column;
}


@media screen and (max-width: 768px) {
   .user-content-container-2 { margin-left: 5%; margin-right: 5%; margin-top: 30%; }
}

@media screen and (max-height: 500px){
   .user-container-2 { height: 100% }
}

@media screen and (min-width: 320px){
   .user-container-2 { height: 100% }
}

@media screen and (max-width: 768px) {
   .user-custom-row.ant-row.ant-form-item{ width: 100%; }
   .user-wrap-content-container { width: 80%; }
}

@media screen and (max-width: 1024px) {
   .user-anchor.ant-anchor-wrapper { display: none }
}

.user-layout {
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 2000px) {
  .userprofile-content-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: left;
    background: #fff;
    border-radius: 0px;
    padding: 20px;
    width: 80%;
    margin-top: 28vh;
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .userprofile-content-container {
    position: relative;
    display: flex;
    flex: none;
    flex-direction: column;
    align-items: left;
    background: #fff;
    border-radius: 0px;
    padding: 0px;
    width: 80%;
    margin-top: 28vh;
    margin-bottom: 10%;
  }
}


.user-limit-content-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: left;
  background: linear-gradient(to right, rgb(41, 108, 187), #154989);
  border-radius: 0px;
  padding: 20px;
  width: 100%;
}

.ant-layout {
  background-color: #fff;
}

.profile-tf-2 {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.profile-box {
  display: flex;
  margin-left: 0px;
  padding: 20px;
  overflow: scroll;
  flex-wrap: wrap;
}

.profile-box-right {
  flex: 3;
  margin-right: 10px;
  margin-left: 10px;
}
.profile-box-center {
  flex: 1;
  }

.profile-box-left {
  flex: 3;
  width: 100%;
}

.ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #0b5ca9;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .userprofile-content-container {
    margin-top: 30vh;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 100%;
  }
  .profile-box-center {
    flex: 0;
  }
  .profile-box {
    padding: 0;
  }
  .profile-body.ant-form.ant-form-horizontal {
    width: 100%;
    margin-left: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
  .user-body.ant-form.ant-form-horizontal { padding-left: 5%; padding-right: 5%;}
  .documents-box {
    margin-left: 0;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
  }
  .documents-box-form {
    padding-left: 5%;
    padding-right: 5%;
  }
  .fica-box {
    position: relative;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: auto;
    width: 100%;
  }

}

@media screen and (max-width: 500px) {
  .userprofile-content-container {
    margin-top: 35vh;
    margin-left: 3vw;
    margin-right: 3vw;
    width: 100%;
  }
  .user-fica-parent-details {
    justify-content: center;
    margin-top: 40px;
  }
}
